import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    overflow: 'auto',
    height: '273px',
  },
}));

export default useStyles;
