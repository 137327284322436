import { ReactElement } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { SDSIcon, SDSInfoMsg, SDSSubstanceApproval } from 'interfaces';
import { SDSSubHeaderRisk } from 'interfaces/substance';
/* Components */
import { ClassNameMap, Typography } from '@mui/material';
import RiskRatings from './risk-rating';
import AlertInfo from 'components/alert-info';

interface SDSDetailSubHeaderProps {
  approval?: SDSSubstanceApproval | null;
  pictogram?: Array<SDSIcon | string>;
  risk?: SDSSubHeaderRisk | null;
  infoMsg?: SDSInfoMsg | null;
}

const SDSDetailSubHeader = ({
  approval,
  pictogram,
  risk,
  infoMsg,
}: SDSDetailSubHeaderProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();

  const renderSubstanceApproval = (): ReactElement | null => {
    if (!approval) return null;
    return (
      <div className={classes.approvalContainer}>
        <div className={classes.approvalContent}>
          <Typography style={{ fontWeight: 600, flex: 1 }}>
            {t(`sds_detail:${approval.status}`)}
          </Typography>
        </div>
        {approval.created_by && (
          <div className={classes.approvalInfo}>
            <Typography>
              {t('sds_detail:updated_by')?.toLowerCase()}
              {': '}
              {approval.created_by}
              {' - '}
              {approval.created_at}
            </Typography>
            {approval.comment && (
              <Typography>{`(${approval.comment})`}</Typography>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderPictograms = (): ReactElement | null => {
    if (!pictogram) return null;
    return (
      <div className={classes.pictogramContainer}>
        <div className={classes.pictogramContent}>
          {pictogram.length > 0 ? (
            pictogram.map((el: SDSIcon | string, i: number) => (
              <div key={`ghs-pictogram-${i}`} className={classes.pictogramImg}>
                <img
                  src={typeof el === 'string' ? el : el.url}
                  alt={'GHS'}
                  width={40}
                  height={40}
                  style={{ marginLeft: '5px' }}
                />
              </div>
            ))
          ) : (
            <div className={classes.pictogramEmptyBox} />
          )}
        </div>
      </div>
    );
  };

  const renderInfoMsg = (): ReactElement | null => {
    if (!infoMsg) return null;
    return (
      <div className={classes.infoMsgContainer}>
        <div className={classes.infoMsgContent}>
          <AlertInfo infoMsg={infoMsg} alertContainerStyle={{ flex: 1 }} />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.pictogramRiskRatingBlock}>
        {renderPictograms()}
        {risk && <RiskRatings risk={risk} />}
      </div>
      {renderSubstanceApproval()}
      {renderInfoMsg()}
    </div>
  );
};

export default SDSDetailSubHeader;
