import useStyles from './styles';
import {
  ReactNode,
  useState,
  MouseEvent,
  ChangeEvent,
  ReactElement,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Checkbox,
  TableCellProps,
  Paper,
  MenuItem,
  Menu,
  ClassNameMap,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomTooltip from '../custom-tooltip';

interface EnhancedTableColumn {
  id: string;
  title: string;
  align?: string;
  minWidth?: number | string;
  width?: number | string;
}

interface EnhancedTableAction<T> {
  label: string;
  action: (value: T) => void;
  show: (row: any) => boolean;
}

interface EnhancedTableData {
  id: string | number;
}

interface EnhancedTableProps<T extends EnhancedTableData = any> {
  dataRows: Array<T>;
  columns: Array<EnhancedTableColumn>;
  renderRow: (
    value: T,
    columnId: string,
  ) => ReactNode | ReactElement | string | null;
  page?: number;
  rowsPerPage?: number;
  setPage?: (value: number) => void;
  setRowsPerPage?: (value: number) => void;
  count?: number;
  actions?: Array<EnhancedTableAction<T>>;
  onRowClick?: (row: T) => void;
  onRowDoubleClick?: (row: T) => void;
  enableCheckbox?: boolean;
  needTranslated?: boolean;
  classNameRow?: (row: T) => string;
  height?: number;
  renderSumRow?: () => ReactNode;
  renderInLocationMessage?: (row: T) => ReactNode | null;
  getRowTooltip?: (value: T) => string | null;
  renderEmptyRow?: () => ReactElement | null;
  tableContainerClassName?: string;
}

function EnhancedTable<T extends EnhancedTableData = any>(
  props: EnhancedTableProps<T>,
) {
  const {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    count,
    dataRows,
    renderRow,
    columns,
    actions,
    onRowClick,
    onRowDoubleClick,
    enableCheckbox = false,
    needTranslated = false,
    classNameRow = () => '',
    height,
    renderSumRow,
    renderInLocationMessage,
    getRowTooltip,
    renderEmptyRow,
    tableContainerClassName,
  } = props;
  const classes: ClassNameMap = useStyles();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Array<string | number>>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [rowIndexSelected, setRowIndexSelected] = useState<number | null>(null);
  const open = Boolean(anchorEl);
  const rowCount = dataRows.length;
  const numSelected = selected.length;

  const handleMenuClick = (
    event: MouseEvent<HTMLButtonElement>,
    index: number,
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    setRowIndexSelected(index);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
    setRowIndexSelected(null);
  };

  const handleChangePage = (newPage: number): void => {
    setPage !== undefined && setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any): void => {
    setRowsPerPage !== undefined && setRowsPerPage(+event.target.value);
    setPage !== undefined && setPage(0);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelecteds = dataRows.map((item: T) => item.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string | number): void => {
    const isRowSelected = selected.indexOf(id);
    let newSelected: Array<string | number> = [];

    if (isRowSelected === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (isRowSelected === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (isRowSelected === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (isRowSelected > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, isRowSelected),
        selected.slice(isRowSelected + 1),
      );
    }
    setSelected(newSelected);
  };

  const renderTableRow = (row: T, index: number): ReactElement => {
    const isRowSelected = selected.indexOf(row.id) !== -1;
    const showAction = actions !== undefined;
    const actionMenuDisplay =
      actions &&
      actions.filter((el: EnhancedTableAction<T>) => el.show(row)).length > 0;

    return (
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        className={`${classes.tableRow} ${classNameRow(row)}`}
        component={'tr'}
        selected={isRowSelected}
        onClick={() => !open && onRowClick !== undefined && onRowClick(row)}
        onDoubleClick={() =>
          onRowDoubleClick !== undefined && onRowDoubleClick(row)
        }
      >
        {enableCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={() => handleClick(row.id)}
              checked={isRowSelected}
            />
          </TableCell>
        )}
        {columns.map((column: EnhancedTableColumn) => (
          <TableCell
            key={column.id}
            align={column.align as TableCellProps['align']}
            style={{ width: column.width }}
          >
            {renderRow(row, column.id)}
          </TableCell>
        ))}
        {showAction &&
          (actionMenuDisplay ? (
            <TableCell align={'right'}>
              <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                size={'small'}
                onClick={e => handleMenuClick(e, index)}
                aria-expanded={open ? 'true' : undefined}
              >
                <MoreVertIcon />
              </IconButton>
            </TableCell>
          ) : (
            <TableCell />
          ))}
        {renderInLocationMessage !== undefined && (
          <TableCell align={'center'} className={classes.inLocationMessageCell}>
            {renderInLocationMessage(row)}
          </TableCell>
        )}
      </TableRow>
    );
  };

  const renderActionMenu = (): ReactElement | null => {
    if (!actions) return null;

    return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={true}
        onClose={handleMenuClose}
        className={classes.menu}
      >
        {actions.map((itemAction: EnhancedTableAction<T>, idx: number) => {
          if (
            rowIndexSelected === null ||
            !itemAction.show(dataRows[rowIndexSelected])
          )
            return null;

          return (
            <MenuItem
              key={idx}
              onClick={() => {
                itemAction.action(dataRows[rowIndexSelected]);
                handleMenuClose();
              }}
            >
              {itemAction.label}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderTableData = (row: T, index: number): ReactNode => {
    const tooltip = getRowTooltip !== undefined && getRowTooltip(row);
    if (tooltip) {
      return (
        <CustomTooltip key={index} title={tooltip}>
          {renderTableRow(row, index)}
        </CustomTooltip>
      );
    }
    return renderTableRow(row, index);
  };

  const renderTableHead = (): ReactElement => {
    return (
      <TableHead>
        <TableRow>
          {enableCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={handleSelectAllClick}
                inputProps={{ 'aria-label': 'select all desserts' }}
              />
            </TableCell>
          )}
          {columns.map((column: EnhancedTableColumn) => {
            return (
              <TableCell
                key={column.id}
                align={column.align as TableCellProps['align']}
                style={{
                  minWidth: column.minWidth ?? 'auto',
                  width: column.width ?? 'auto',
                }}
                className="custom-header-style"
              >
                {needTranslated ? t(column.title) : column.title}
              </TableCell>
            );
          })}
          {actions && <TableCell />}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBody = (): ReactElement => {
    return (
      <TableBody>
        {dataRows.length > 0 ? (
          dataRows.map((row: T, index: number) => renderTableData(row, index))
        ) : renderEmptyRow !== undefined ? (
          renderEmptyRow()
        ) : (
          <TableRow style={{ padding: '10px 0px 10px 10px' }}>
            <TableCell>
              <Typography variant="subtitle2">
                {t('common:no_result')}
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {renderSumRow !== undefined && renderSumRow()}
        {!!anchorEl && renderActionMenu()}
      </TableBody>
    );
  };

  return (
    <Paper
      sx={{
        width: '100%',
        position: 'relative',
      }}
    >
      <TableContainer
        sx={{ height: height ?? 'auto' }}
        classes={{ root: `customScrollbar ${tableContainerClassName}` }}
      >
        <Table stickyHeader aria-label="sticky table">
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
      {page !== undefined &&
        count !== undefined &&
        count > 10 &&
        rowsPerPage !== undefined &&
        setPage !== undefined &&
        setRowsPerPage !== undefined && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => handleChangePage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ backgroundColor: '#F2F5F7', zIndex: 10 }}
            labelRowsPerPage={`${t('common:rows_per_page')}`}
          />
        )}
    </Paper>
  );
}

export default EnhancedTable;
