import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      background: 'transparent',
      boxShadow: 'none',
      minWidth: '60%',
      height: '100%',
      marginTop: '0px',
      [theme.breakpoints.down('lg')]: {
        margin: '20px 4px 0px 4px',
      },
    },
  },
  dialogTitleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    padding: '2px',
    background: 'white',
    borderRadius: '4px',
    display: 'flex',
    cursor: 'pointer',
  },
}));

export default useStyles;
