import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: '10px 0px',
  },
  pictogramRiskRatingBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 15px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  pictogramContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pictogramContent: {
    display: 'flex',
    position: 'relative',
    paddingRight: '40px',
    transform: 'translateX(40px)',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      transform: 'initial',
      alignItems: 'center',
    },
  },
  pictogramImg: {
    display: 'flex',
  },
  pictogramEmptyBox: {
    height: 40,
    width: 40,
  },
  approvalContainer: {
    flex: 1,
    padding: '0px 15px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '350px',
    gap: 5,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      flexWrap: 'wrap',
      marginBottom: 10,
    },
  },
  approvalContent: {
    display: 'flex',
    gap: 30,
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 42,
  },
  approvalInfo: {
    fontStyle: 'italic',
    borderTop: '1px solid rgb(128 128 128 / 20%)',
    '& p': {
      fontSize: '12px!important',
    },
  },
  infoMsgContainer: {
    display: 'flex',
  },
  infoMsgContent: {
    padding: '0px 15px',
    display: 'flex',
    flex: 1,
  },
}));

export default useStyles;
