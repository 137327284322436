import { useState, useEffect } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils';
import { LanguageSelectOption } from 'interfaces/filter';
import { LANGUAGES } from 'constant';
/* Components */
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  ClassNameMap,
} from '@mui/material';

interface LanguageSelectProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  sortOption?: 'asc' | 'desc' | null;
  options?: Array<LanguageSelectOption>;
  fullWidth?: boolean;
}

const LanguageSelect = ({
  id,
  value,
  onChange,
  sortOption,
  options,
  fullWidth,
}: LanguageSelectProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  const [sortedLanguages, setSortedLanguages] = useState<
    Array<LanguageSelectOption>
  >([]);

  useEffect(() => {
    if (options !== undefined) return;

    let languageOptions: Array<LanguageSelectOption> = LANGUAGES;
    if (sortOption === 'asc') {
      languageOptions = LANGUAGES.map((el: LanguageSelectOption) => {
        return {
          ...el,
          name: t(`languages:${el.code}`),
        };
      });
    }
    if (sortOption === 'desc') {
      languageOptions = LANGUAGES.map((el: LanguageSelectOption) => {
        return {
          ...el,
          name: t(`languages:${el.code}`),
        };
      }).sort((a: LanguageSelectOption, b: LanguageSelectOption) =>
        a.name < b.name ? 1 : -1,
      );
    }

    setSortedLanguages(languageOptions);
  }, []);

  useEffect(() => {
    if (options !== undefined) setSortedLanguages(options ?? []);
  }, [options]);

  return (
    <FormControl variant="standard">
      <InputLabel>{t('common:language')}</InputLabel>
      <Select
        id={`${id}-language-select`}
        labelId={`${id}-language-select-input-label`}
        value={value}
        label={`${t('common:language')}`}
        className={classes.selectInput}
        onChange={(e: SelectChangeEvent<string>) => onChange(e.target.value)}
        MenuProps={{
          classes: {
            paper:
              sortedLanguages.length <= 10
                ? !isMobile()
                  ? classes.selectMenu
                  : classes.selectMenuMobile
                : classes.selectMenuMobile,
          },
        }}
        style={fullWidth ? { width: '100%' } : {}}
      >
        <MenuItem key={99999} value={'all'}>
          {t('common:all')}
        </MenuItem>
        {sortedLanguages.map((el: LanguageSelectOption, i: number) => (
          <MenuItem key={i} value={el.code}>
            {t(`languages:${el.code}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
