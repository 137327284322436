import useStyles from './styles';
import { Badge, Button, ButtonProps, ClassNameMap } from '@mui/material';
import { FilterList } from '@mui/icons-material';

interface AdvancedSearchButtonProps extends ButtonProps {
  countFilter: number;
}

const AdvancedSearchButton = ({
  countFilter,
  ...props
}: AdvancedSearchButtonProps) => {
  const classes: ClassNameMap = useStyles();

  return (
    <Button
      sx={{
        fontSize: '12px',
        padding: '6px 15px',
        backgroundColor: '#fff',
        fontWeight: 600,
      }}
      variant={'outlined'}
      color={'primary'}
      {...props}
    >
      {countFilter < 1 ? (
        <FilterList width={16} height={16} />
      ) : (
        <Badge className={classes.badgeColor} badgeContent={countFilter}>
          <FilterList width={16} height={16} />
        </Badge>
      )}
      {props.children && props.children}
    </Button>
  );
};

export default AdvancedSearchButton;
