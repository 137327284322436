import {
  ClassNameMap,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { SDSSubHeaderRisk } from 'interfaces/substance';

const RISK_LEVEL = ['#01ee00', '#fdff00', '#fdcd00', '#ff6600', '#fe3301'];

interface RiskRatingsProps {
  risk: SDSSubHeaderRisk;
}

const RiskRatings = ({ risk }: RiskRatingsProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();

  return (
    <div className={classes.container}>
      <Table className={classes.tableRiskRating}>
        <TableBody>
          <TableRow sx={{ height: '10px' }}>
            <TableCell sx={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
              {t('sds_detail:health_risk_rating')}:
            </TableCell>
            {RISK_LEVEL.map((ele, index) => {
              return (
                <TableCell
                  key={index}
                  className={classes.makeStylesRiskLevel}
                  style={{
                    backgroundColor: ele,
                  }}
                >
                  {risk.healthRisk === index + 1 ? index + 1 : null}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow style={{ height: '10px' }}>
            <TableCell style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>
              {t('sds_detail:safety_risk_rating')}:
            </TableCell>
            {RISK_LEVEL.map((ele, index) => {
              return (
                <TableCell
                  key={index}
                  className={classes.makeStylesRiskLevel}
                  style={{
                    backgroundColor: ele,
                  }}
                >
                  {risk.safetyRisk === index + 1 ? index + 1 : null}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow style={{ height: '10px' }}>
            <TableCell
              style={{
                fontSize: '12px',
                whiteSpace: 'nowrap',
                paddingRight: 10,
              }}
            >
              {t('sds_detail:environmental_risk_rating')}:
            </TableCell>
            {RISK_LEVEL.map((ele, index) => {
              return (
                <TableCell
                  key={index}
                  className={classes.makeStylesRiskLevel}
                  style={{
                    backgroundColor: ele,
                  }}
                >
                  {risk.environmentalRisk === index + 1 ? index + 1 : null}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default RiskRatings;
