import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: '10px',
    width: '164px',
    marginLeft: '10px',
  },
  actionItem: {
    display: 'flex',
    alignItems: 'center',
  },
  actionImgContainer: {
    backgroundColor: 'rgb(242, 245, 247)',
    width: 20,
    height: 20,
  },
  actionImg: {
    width: 18,
    height: 18,
    margin: 1,
  },
}));

export default useStyles;
