import { ReactElement } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
/* Components */
import { ClassNameMap, Typography, Pagination } from '@mui/material';
import CustomLoader from 'components/loader';

type CardColumn = {
  title: string;
  id: string;
};

interface DefaultCardTableMobileProps {
  id?: string | number | null;
}

interface CardTableMobileProps<T extends DefaultCardTableMobileProps = any> {
  column: Array<CardColumn>;
  data: Array<T>;
  renderValue: (item: T, id: string) => ReactElement | string | number | null;
  loading?: boolean;
  count?: number;
  page?: number;
  handlePageChange?: any;
  renderAction?: (row: T) => ReactElement;
  actionPos?: 'bottom' | 'top' | 'top-right';
  hiddenNameLabel?: boolean;
  onClick?: (item: any) => void;
  renderEmptyRow?: () => ReactElement | null;
}

function CardTableMobile<T extends DefaultCardTableMobileProps = any>(
  props: CardTableMobileProps<T>,
) {
  const {
    column,
    data,
    renderValue,
    loading,
    count,
    page,
    handlePageChange,
    renderAction,
    actionPos = 'bottom',
    onClick,
    renderEmptyRow,
  } = props;
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();

  if (loading !== undefined && loading) {
    return (
      <div className={classes.loader}>
        <CustomLoader />
      </div>
    );
  }

  return (
    <>
      {data.length > 0 ? (
        data.map((item: T) => (
          <div className={classes.card} key={item.id}>
            {renderAction && actionPos === 'top' && renderAction(item)}
            {column.map((col: CardColumn, index: number) => {
              if (!col.id) return null;
              const value = renderValue(item, col.id);
              if (!value) return null;
              if (['detail', 'action'].includes(col.id)) return null;

              return (
                <div
                  className={classes.infoBlock}
                  key={col.id}
                  onClick={() => onClick && onClick(item)}
                >
                  <span className={classes.valuesColor}>{col.title}: </span>
                  {renderValue(item, col.id)}
                </div>
              );
            })}
            {renderAction && actionPos === 'bottom' && renderAction(item)}
          </div>
        ))
      ) : renderEmptyRow !== undefined ? (
        renderEmptyRow()
      ) : (
        <Typography variant="subtitle2">{t('common:no_result')}</Typography>
      )}
      {count !== undefined &&
        count > 10 &&
        page !== undefined &&
        handlePageChange && (
          <div className={classes.pagination}>
            <Pagination
              defaultPage={6}
              count={Math.ceil(count / 10)}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </div>
        )}
    </>
  );
}

export default CardTableMobile;
