import { Box, Typography } from '@mui/material';

interface RiskValueProps {
  value: number | null | undefined;
  prefix: string;
  variant: 'outlined' | 'contained';
}

const RiskValue = ({ value, prefix, variant }: RiskValueProps) => {
  const getBackgroundColor = (): string => {
    return !value
      ? '#cccccc'
      : value <= 2
        ? '#008000'
        : value === 3
          ? '#FFFF00'
          : value === 4
            ? '#FFA500'
            : '#FF0000';
  };

  const color = getBackgroundColor();
  if (!value) {
    return (
      <Box
        style={{
          minHeight: '20px',
          minWidth: '20px',
          width: '20px',
          height: '20px',
          padding: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `2px solid`,
          borderColor: color,
          boxSizing: 'unset',
        }}
        className="custom-style-box"
      >
        <Typography
          style={{
            color: '#000',
          }}
        >
          {'-'}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      style={{
        minHeight: '20px',
        minWidth: '20px',
        width: '20px',
        height: '20px',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: variant === 'contained' ? color : 'none',
        border: `2px solid`,
        borderColor: color,
        boxSizing: 'unset',
      }}
      className="custom-style-box"
    >
      <Typography
        style={{
          color: variant === 'outlined' || value === 3 ? '#000' : '#fff',
          backgroundColor: 'transparent',
        }}
      >
        {value ? `${prefix}${value}` : ''}
      </Typography>
    </Box>
  );
};

export default RiskValue;
