import useStyles from './styles';
import { ClassNameMap, Skeleton } from '@mui/material';

interface CardTableLoadingDesktopProps {
  rowCount: number;
}

const CardTableLoadingDesktop = ({
  rowCount = 5,
}: CardTableLoadingDesktopProps) => {
  const classes: ClassNameMap = useStyles();

  return (
    <div className={classes.container}>
      <Skeleton
        sx={{
          width: '100%',
          height: '72px',
          marginBottom: '5px',
        }}
      />
      <div className={classes.tableContainer}>
        {rowCount > 0 &&
          [...Array(rowCount)].map((_, index: number) => (
            <Skeleton
              key={index}
              sx={{
                width: '100%',
                height: '77px',
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default CardTableLoadingDesktop;
