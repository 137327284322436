import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      boxShadow: 'none',
      marginTop: '0px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  appIcon: {
    width: '28px',
    height: '28px',
  },
  actionContainer: {
    display: 'flex',
    gap: '10px',
    paddingBottom: '20px',
  },
  actionBtn: {
    width: '160px',
  },
  titleContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
}));

export default useStyles;
