import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    cursor: 'pointer',
    padding: '4px',
    borderRadius: '6px',
    display: 'flex',
    height: 'fit-content'
  },
});

export default useStyles;
