import axiosAuthoringInstance from '../axios-client';

export const exportUserSds = (from?: string | null) => {
  return axiosAuthoringInstance
    .get(`/v2/substances/exportUserSds/${from ? '?from=' + from : ''}`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const exportLiterals = () => {
  return axiosAuthoringInstance
    .get('/v2/substances/exportLiterals/')
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getPdfFileByUrl = (url: string) => {
  return axiosAuthoringInstance
    .get(url, { responseType: 'blob' })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getAllPdfs = (params: any) => {
  const newParams = new URLSearchParams();
  newParams.append('search_product', params.search_product);
  newParams.append('search_producer', params.search_producer);
  params.search_product_code &&
    newParams.append('search_product_code', params.search_product_code);
  params.search_cas_no &&
    newParams.append('search_cas_no', params.search_cas_no);
  newParams.append('page', params.page);
  newParams.append('page_size', params.page_size);
  params.language_code &&
    newParams.append('language_code', params.language_code);
  params.region?.map((el: string) => newParams.append('region', el));
  newParams.append('is_in_use_by_customer', params.is_in_use_by_customer);
  params.with_used_in_locations &&
    newParams.append('with_used_in_locations', params.with_used_in_locations);
  params.advanced_search &&
    newParams.append('advanced_search', params.advanced_search);
  if (params.minimum_year)
    newParams.append('minimum_year', params.minimum_year);

  if (params.allow_not_current_verison)
    newParams.append(
      'allow_not_current_verison',
      params.allow_not_current_verison,
    );

  return axiosAuthoringInstance
    .get('/pdfs/', { params: newParams })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const getSdsDetail = (sdsId: number) => {
  return axiosAuthoringInstance
    .get(`/pdfs/${sdsId}/`, {
      params: { allowView: true },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
