import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RegionSelectOption } from 'interfaces/filter';
import { FilterOption } from 'interfaces/global-search-sds';
import { REGIONS } from 'constant';
import AutoComplete from '../auto-complete';

interface RegionSelectProps {
  id: string;
  value: Array<FilterOption<string>>;
  onChange: (value: Array<FilterOption<string>>) => void;
  sortOption?: 'asc' | 'desc' | null;
  optionDisabled?: (option: FilterOption<string>) => boolean;
  disabled: boolean;
}

const RegionSelect = ({
  id,
  value,
  onChange,
  sortOption,
  optionDisabled,
  disabled,
}: RegionSelectProps) => {
  const { i18n, t } = useTranslation();
  const [sortedRegions, setSortedRegions] = useState<Array<RegionSelectOption>>(
    [],
  );

  const sortRegions = (): void => {
    let regionOptions = REGIONS;
    const anyOption = [{ name: t('common:no_region_limit'), value: 'Any' }];
    if (sortOption === 'desc') {
      regionOptions = REGIONS.sort(
        (a: RegionSelectOption, b: RegionSelectOption) =>
          a.name < b.name ? 1 : -1,
      );
    }
    setSortedRegions(anyOption.concat(regionOptions));
  };

  useEffect(() => {
    sortRegions();
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', sortRegions);

    return () => {
      i18n.off('languageChanged', sortRegions);
    };
  }, [i18n]);

  return (
    <AutoComplete
      id={`${id}-region-select`}
      disabled={disabled}
      onChange={onChange}
      options={sortedRegions}
      optionDisabled={optionDisabled}
      label={`${t('common:region')}`}
      value={value}
      anyValue={{ name: 'common:no_region_limit', value: 'any' }}
      noCustomWidth={true}
    />
  );
};

export default RegionSelect;
