import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  selectInput: {
    width: '185px',
    '& .MuiMenuItem-root': {
      fontSize: '14px',
    },
  },
  selectCustomMenu: {
    height: '250px',
  },
}));

export default useStyles;
