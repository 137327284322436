import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '../i18n/resources';

let initialized = false;

export type Language = string;
export const languages: string[] = [
  'en',
  'no',
  'de',
  'da',
  'sv',
  'nl',
  'fi',
  'el',
  'es',
  'fr',
  'it',
  'pl',
  'pt',
  'bg',
  'bs',
  'cs',
  'et',
  'hi',
  'hr',
  'hu',
  'id',
  'is',
  'ja',
  'lt',
  'lv',
  'ms',
  'mt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sq',
  'sr',
  'tr',
  'uk',
  'vi',
  'zh',
];

export const supportedLanguages = [
  'da',
  'de',
  'el',
  'es',
  'et',
  'fi',
  'fr',
  'hi',
  'hu',
  'id',
  'is',
  'it',
  'ja',
  'en',
  'lt',
  'lv',
  'mt',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sv',
  'sk',
  'sl',
  'tr',
  'vi',
  'cs',
  'zh',
  'uk',
];
export const defaultLanguage = 'en';

export function getI18n(defaultOptions: { lng: string }): i18n {
  if (!initialized) {
    const options = {
      debug: process.env.NODE_ENV === 'development',
      resources,
      fallbackLng: 'en',
      defaultNS: 'common',
      react: {
        useSuspense: false,
      },
      ...defaultOptions,
    };
    i18next.use(initReactI18next).init(options);
    initialized = true;
  }

  return i18next;
}

export default i18next;
