import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  badgeColor: {
    '& .MuiBadge-badge': {
      backgroundColor: 'red',
      width: 16,
      height: 16,
      minWidth: 'auto',
      top: '4px',
      right: '2px',
      fontSize: 10,
      color: 'white'
    },
  },
}));

export default useStyles;