import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  actionWrapper: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: 14,
  },
  actionButton: {
    display: 'flex',
    gap: 10,
    height: 'fit-content',
  },
}));

export default useStyles;
