import {
  Checkbox,
  FormControl,
  Autocomplete as MuiAutocomplete,
  TextField,
  Typography,
  ClassNameMap,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { FilterOption } from 'interfaces/global-search-sds';

interface AutoCompleteProps {
  id: string;
  disabled: boolean;
  onChange: (value: Array<FilterOption<string>>) => void;
  options: Array<FilterOption<string>>;
  optionDisabled?: (option: FilterOption<string>) => boolean;
  label: string;
  value: Array<FilterOption<string>>;
  anyValue?: FilterOption<string>;
  groupBy?: (option: FilterOption<string>) => string;
  noCustomWidth?: boolean;
}

const AutoComplete = ({
  id,
  disabled,
  onChange,
  options,
  optionDisabled,
  label,
  value,
  anyValue,
  groupBy,
  noCustomWidth,
}: AutoCompleteProps) => {
  const { i18n, t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  const [selectedValue, setSelectedValue] = useState<
    Array<FilterOption<string>>
  >([]);
  const [anyValueSelected, setAnyValueSelected] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    setSelectedValue(value);
    if (value.some(option => option.value.toLowerCase() === anyValue?.value))
      setAnyValueSelected(true);
  }, [value]);

  useEffect(() => {
    if (anyValueSelected && anyValue) {
      setSelectedValue([{ name: t(anyValue?.name), value: 'Any' }]);
    }
  }, [i18n.language]);

  return (
    <FormControl variant="standard">
      <MuiAutocomplete
        multiple
        loadingText={`${t('common:loading')}...`}
        noOptionsText={t('common:no_suggestions')}
        id={`${id}-auto-complete`}
        options={options}
        value={selectedValue}
        onChange={(_, value: Array<FilterOption<string>>, reason) => {
          if (reason === 'clear') {
            setAnyValueSelected(false);
            onChange([]);
            return;
          }
          if (
            reason === 'removeOption' &&
            anyValue &&
            selectedValue
              .find(option => !value.includes(option))
              ?.value.toLowerCase() === anyValue?.value
          ) {
            setAnyValueSelected(false);
            onChange(value);
          } else if (
            anyValue &&
            value.some(option => option.value.toLowerCase() === anyValue?.value)
          ) {
            setAnyValueSelected(true);
            onChange(value);
          } else {
            setHasChanged(true);
            setAnyValueSelected(false);
            setSelectedValue(value);
          }
        }}
        onClose={() => {
          if (hasChanged) {
            setHasChanged(false);
            onChange(selectedValue);
          }
        }}
        disableCloseOnSelect
        disabled={disabled}
        renderOption={(props, option, { selected }) =>
          anyValue && option.value.toLowerCase() === anyValue?.value ? (
            <li {...props} key={props.key}>
              {option.name}
            </li>
          ) : (
            <li {...props} key={props.key}>
              <Checkbox checked={selected} />
              {option.name}
            </li>
          )
        }
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionDisabled={option => {
          if (optionDisabled) return optionDisabled(option);
          return false;
        }}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            className={classes.inputField}
          />
        )}
        renderTags={value => {
          const multiValue = value.map(option => option.name).join(', ');
          return (
            <Typography className={classes.valueField}>{multiValue}</Typography>
          );
        }}
        componentsProps={
          noCustomWidth
            ? {}
            : {
                popper: {
                  style: { width: 350 },
                },
              }
        }
        sx={{
          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            minWidth: 0,
          },
        }}
        groupBy={option => (groupBy ? groupBy(option) : '')}
      />
    </FormControl>
  );
};

export default AutoComplete;
