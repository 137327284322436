import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      background: 'transparent',
      boxShadow: 'none',
      minWidth: '60%',
      height: '100%',
      marginTop: '0px',
      [theme.breakpoints.down('lg')]: {
        margin: '20px 4px 0px 4px',
      },
    },
  },
  dialogTitleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    padding: '2px',
    background: 'white',
    borderRadius: '4px',
    display: 'flex',
    cursor: 'pointer',
  },
  contentWrapper: {
    width: '99.9%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    padding: '0px 0px 30px 0px',
    boxShadow: '0px 5px 30px 10px rgba(34, 60, 80, 0.2);',
    borderRadius: '4px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  infoContainer: {
    padding: '0px 15px',
  },
  sectionTitle: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.colors.light,
    paddingLeft: '15px',
  },
  sectionTitleText: {
    '&.MuiTypography-root': {
      fontWeight: 'bold',
      color: theme.palette.colors.priamry,
    },
  },
  fieldContainer: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0',
      width: '100%',
    },
  },
  fieldLabel: {
    '&.MuiTypography-root': {
      paddingLeft: '15px',
      paddingTop: '1%',
      paddingBottom: '1%',
      width: '23%',
      color: theme.palette.colors.textLight,
      backgroundColor: theme.palette.backgroundColor.main,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderBottom: '1px solid gainsboro',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        width: '98%',
        fontSize: '0.8rem',
        fontWeight: '600',
        paddingLeft: '5px',
      },
    },
  },
  fieldValue: {
    position: 'relative',
    paddingTop: '1%',
    paddingBottom: '1%',
    paddingLeft: '3%',
    color: '#00324E',
    flex: 1,
    borderBottom: '1px solid gainsboro',
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      fontSize: '0.8rem',
    },
  },
  fieldIconValue: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    paddingTop: '1%',
    paddingBottom: '1%',
    paddingLeft: '3%',
    flex: 1,
    borderBottom: '1px solid gainsboro',
  },
}));

export default useStyles;
