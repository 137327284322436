import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 20px',
    background: '#F2F5F7',
    height: 'calc(100vh - 10px)',
  },
}));

export default useStyles;
