import React from 'react';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';

const palette = {
  primary: {
    main: '#626DF9',
  },
  secondary: {
    main: '#000',
  },
  hoverColor: {
    main: '#e0e7fa',
  },
  backgroundColor: {
    main: '#F2F5F7',
  },
  colors: {
    green: '#008000',
    yellow: '#FFFF00',
    amber: '#FFA500',
    red: '#FF0000',
    gray: '#cccccc',
    white: '#fff',
    text: '#000',
    primary: '#626DF9',
    secondary: '#000',
    light: '#e0e7fa',
    dark: '#0006D2',
    textLight: '#52525F',
  },
};

const typography = {
  fontFamily: 'Montserrat, sans-serif',
  h1: {
    fontSize: 48,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h2: {
    fontSize: 36,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h3: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h4: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h5: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.5,
  },
  body1: {
    fontSize: 14,
    '@media print': {
      fontSize: 12,
    },
  },
  body2: {},
  subtitle1: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 1.75,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: 1.57,
  },
  caption: {
    fontWeight: 500,
  },
  overline: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 1,
    lineHeight: 1.66,
  },
  button: {
    fontWeight: 500,
    textTransform: undefined,
  },
};
export const theme = createTheme({
  palette: palette,
  typography: typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat, sans-serif',
          minWidth: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialogActions-root': {
            fontWeight: 500,
            justifyContent: 'center',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          '& .MuiButtonBase-root': {
            position: 'absolute',
            top: 8,
            right: 8,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButton-label': {
            textTransform: 'uppercase',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            fontSize: 14,
          },
          '& .MuiInputBase-root': {
            fontSize: 14,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontFamily: 'Montserrat, sans-serif',
          padding: '10px',

          '&.MuiTableCell-head': {
            fontWeight: 600,
            color: '#52525F',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          fontSize: 20,
          '&.MuiTableRow-hover:hover': {
            backgroundColor: palette.hoverColor.main,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontFamily: 'Montserrat, sans-serif',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&:hover': {
            backgroundColor: palette.hoverColor.main,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: 20,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: 'none',
        },
      },
    },
  },
});

const AppThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </ThemeProvider>
  );
};

export default AppThemeProvider;
