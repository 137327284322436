import { makeStyles } from '@mui/styles';
import { COLUMN_GAP, TABLE_LEFT_PADDING } from 'components/card-table/helper';

const useStyles = makeStyles(() => ({
  cardTableSubstance: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    minWidth: '100%',
    marginBottom: 10,
    backgroundColor: '#FFFFFF',
    color: '#52525F',
  },
  cardTableSubstanceInfo: {
    border: '1px solid #cccccc8f',
    borderRadius: 4,
    display: 'flex',
    width: 'calc(100% - 2px)',
    flexShrink: 0,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#ECF7FF',
      '& .cardTableCeil': {
        backgroundColor: '#ECF7FF',
      },
    },
  },
  cardTableSubstanceInfoCol: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    padding: `20px 20px ${COLUMN_GAP}px 0px`,
    '&:first-child': {
      paddingLeft: TABLE_LEFT_PADDING,
      flex: 1,
    },
    '&:nth-child(2)': {
      flex: 1,
    },
  },
  cardTableInfoBody: {
    display: 'flex',
    flex: 1,
  },
  cardTableSubstanceInfoInfoMsg: {
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    borderLeft: '1px solid #cccccc8f',
    borderRight: '1px solid #cccccc8f',
    borderBottom: '1px solid #cccccc8f',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
}));

export default useStyles;
