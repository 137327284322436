import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  searchWrapper: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #CCCCCC',
    borderRadius: 50,
    alignItems: 'center',
  },
  searchWrapperAnimation: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #CCCCCC',
    borderRadius: 50,
    alignItems: 'center',
    animation: '$slideInFromLeft 3s ease-in-out',
  },
  searchInput: {
    fontSize: '14px',
    border: 'none',
    width: '100%',
    padding: '0px 0px 0px 10px',
    fontFamily: 'Montserrat, sans-serif',
    backgroundColor: '#fff!important',
    borderRadius: 50,
    '&:focus': {
      outline: 'none',
    },
    '&:before, &:after': {
      border: '0 !important',
    },
  },
  iconButton: {
    padding: 0,
    backgroundColor: '#1570EF',
    '&:hover': {
      backgroundColor: '#444CAE',
    },
  },
  iconButtonAnimation: {
    padding: 0,
    backgroundColor: '#1570EF',
    '&:hover': {
      backgroundColor: '#444CAE',
    },
    animation: '$rotateY 3s ease', // Adjust the duration and other properties as needed
  },
  clearTextButton: {
    padding: 0,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  // Animation keyframes
  '@keyframes slideInFromLeft': {
    '0%': {
      width: '0',
      border: '1px solid #FFF',
    },
    '20%': {
      width: '0',
      border: '1px solid #FFF',
    },
    '100%': {
      width: '100%',
    },
  },
  '@keyframes rotateY': {
    '0%': {
      transform: 'translateY(-300%)',
    },
    '25%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'rotateY(90deg)',
    },
    '70%': {
      transform: 'rotateY(45deg)',
    },
    '100%': {
      transform: 'rotateY(0deg)',
    },
  },
}));

export default useStyles;
