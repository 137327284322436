import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { getTableColumns } from '../helper';
import { SDSInterface } from 'interfaces';
import { ColumnHeaderInterface } from 'interfaces/card-table';
/* Components */
import { ClassNameMap, Typography } from '@mui/material';
import CardTableSubstance from './card-table-substance';

interface CardTableProps {
  data: Array<SDSInterface>;
  onViewSubstanceDetail: (data: SDSInterface, toSection?: number) => void;
}

const CardTable = ({ data, onViewSubstanceDetail }: CardTableProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  let columns: Array<ColumnHeaderInterface> = getTableColumns();

  const getHeaderCol = (): ReactElement => {
    return (
      <div className={classes.cardTableSticky}>
        <div className={classes.cardTableHeader}>
          <div className={classes.cardTableHeaderWrapper}>
            {columns.map((item: ColumnHeaderInterface) => {
              return (
                <div
                  key={`card-table-header-${item.id}`}
                  style={{
                    width: item.width,
                    alignItems: item.align,
                  }}
                  className={`${classes.cardTableHeaderCol}`}
                >
                  {item.id === 'substance_hazard' ? (
                    <>
                      <div className={classes.hseLabel}>
                        {' '}
                        {t(`common:${item.id}`)}{' '}
                      </div>
                      <div className={classes.hseWrapper}>
                        <Typography className={classes.hseValue}>
                          {'H '}
                        </Typography>
                        <Typography className={classes.hseValue}>
                          {'S '}
                        </Typography>
                        <Typography className={classes.hseValue}>
                          {'E '}
                        </Typography>
                      </div>
                    </>
                  ) : (
                    t(`common:${item.id}`)
                  )}
                </div>
              );
            })}
          </div>

          <div className={'freezeColumnHeading'}>
            <div className={classes.cardHeadingActions}>
              {t('common:actions')}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.cardTableContainer}>
      {getHeaderCol()}
      {data.map((item: SDSInterface) => (
        <CardTableSubstance
          key={`card-table-substance-${item.id}`}
          data={item}
          columns={columns}
          onViewSubstanceDetail={(toSection?: number) =>
            onViewSubstanceDetail(item, toSection)
          }
        />
      ))}
    </div>
  );
};

export default CardTable;
