import { CACHE_NAME } from 'api';
import { format, isToday, parseISO } from 'date-fns';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import i18n from 'i18next';

export function validateToken(token: string | null): boolean {
  if (!token) {
    return false;
  }
  const pattern = /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-+/=]*)/g;
  return pattern.test(token);
}

export const putCache = (url: string, data: any) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'PUT_CACHE',
      url: url,
      data: data,
    });
  } else {
    console.error('Service Worker controller not available.');
  }
};

export const cacheFile = async (
  url: string,
  file: Blob,
  contentType: string,
) => {
  if ('caches' in window) {
    const cache = await caches.open(CACHE_NAME);
    const response = new Response(file, {
      headers: { 'Content-Type': contentType },
    });
    await cache.put(url, response);
  }
};

export const isMobile = (): boolean => {
  return window.matchMedia('(max-width: 767px)').matches;
};

export const isNumber = (value: string) => {
  const numStrPattern =
    /^-?(\d+\.?\d*)$|(\d*\.?\d+)$|^-?(\d+,?\d*)$(\d*,?\d+)$/;
  return numStrPattern.test(value);
};

export const getRecentYears = (count: number): number[] => {
  let currYear = new Date().getFullYear();
  return [...Array(count)].map((_, i) => currYear - i);
};

export const trimString = (value: string | null | undefined): string => {
  if (!value) {
    return '';
  }
  return value.trim();
};

export const formatISODate = (date: string): string | null => {
  if (!date) return null;
  try {
    const convertedDate = parseISO(date);
    let formattedDate = format(convertedDate, 'dd/MM/yyyy');
    if (isToday(convertedDate))
      formattedDate = format(convertedDate, 'dd/MM/yyyy HH:mm:ss');
    return formattedDate;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const renderSnackbar = (message: string): void => {
  enqueueSnackbar(message, {
    action: key => (
      <p className="snackbar-action-btn" onClick={() => closeSnackbar(key)}>
        {i18n.t('common:close')}
      </p>
    ),
  });
};

export const removeCache = async (): Promise<boolean> => {
  return new Promise(resolve => {
    caches.keys().then(cacheNames => {
      try {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
        resolve(true);
      } catch (err) {
        console.error(err);
        resolve(false);
      }
    });
  });
};
