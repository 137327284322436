import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: 'calc(100% - 2px)',
    border: '1px solid #E0E0E0',
    marginTop: 10,
    boxShadow: 'none',
    borderRadius: '2px',
    background: 'white',
    '&:before': {
      display: 'none',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '12px 16px',
  },
  cardTableFieldsList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '7px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: '5px',
    },
  },
  cardTableField: {
    fontSize: '14px',
    color: 'rgb(82, 82, 95)',
    '&:nth-child(1)': {
      fontSize: '16px',
      fontWeight: 600,
      width: '100%',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      order: 1,
    },
    '&:nth-child(2)': {
      width: '100%',
      order: 2,
    },
    '&:nth-child(3)': {
      width: '50%',
      order: 3,
      marginBottom: '10px',
    },
    '&:nth-child(4)': {
      width: '45%',
      order: 4,
    },
    '&:nth-child(5)': {
      order: 6,
      fontWeight: 600,
      width: '40%',
    },
    '&:nth-child(6)': {
      width: '100%',
      order: 5,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '&:nth-child(7)': {
      width: '100%',
      order: 7,
      margin: '10px 0px',
    },
    '&:nth-child(8)': {
      width: 'calc(60% - 7px)',
      order: 6,
    },
    '& .custom-style-box': {
      padding: '2px!important',
    },
  },
  actionGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    order: 99,
  },
}));

export default useStyles;
