import { ReactElement, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { CACHE_NAME } from 'api';
import { getPdfFileByUrl } from 'api/substances';
import { cacheFile, isMobile } from 'utils';
import { ApiResponse } from 'interfaces/api';
/* Components */
import { ClassNameMap } from '@mui/material';
import { PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import PdfViewPopup from 'components/popup/pdf-view-popup';
import IconStyled from 'components/icons-styled';
import CustomTooltip from 'components/custom-tooltip';

interface PdfViewButtonProps {
  url?: string | null;
  styled?: boolean;
  cachePdf?: boolean;
  tooltip?: boolean;
}

const PdfViewButton = ({
  url,
  styled = true,
  cachePdf = true,
  tooltip = true,
}: PdfViewButtonProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  /* State */
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const actionStyle = url
    ? { fontSize: isMobile() ? '18px' : '20px' }
    : { color: '#cccccc', fontSize: isMobile() ? '18px' : '20px' };

  const previewPdf = async (): Promise<void> => {
    if (!url) return;

    let pdfUrl = url;
    if (cachePdf) {
      const cache = await caches.open(CACHE_NAME);
      const response = await cache.match(url);
      if (!response) {
        getPdfFileByUrl(url).then((response: ApiResponse<Blob>) => {
          if (response.status === 200)
            cacheFile(url, response.data, 'application/pdf');
        });
      } else {
        const pdfBlob = await response.blob();
        pdfUrl = URL.createObjectURL(pdfBlob);
      }
    }

    if (!isMobile()) {
      setPdfUrl(pdfUrl);
      return;
    }

    const link = document.createElement('a');
    link.href = pdfUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderActionBtn = (): ReactElement => {
    if (!styled) {
      return (
        <PictureAsPdfIcon
          style={actionStyle}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            previewPdf();
          }}
        />
      );
    }

    return (
      <div className={classes.actionWrapper}>
        <div className={classes.actionButton}>
          <IconStyled
            customHoverStyles={{
              backgroundColor: 'rgb(98 109 249 / 20%)!important',
              '& *': {
                backgroundColor: 'transparent!important',
              },
            }}
          >
            <PictureAsPdfIcon
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                previewPdf();
              }}
              style={actionStyle}
            />
          </IconStyled>
        </div>
      </div>
    );
  };

  return (
    <>
      {tooltip ? (
        <CustomTooltip title={t('common:view_pdf')} placement="bottom-end">
          {renderActionBtn()}
        </CustomTooltip>
      ) : (
        renderActionBtn()
      )}
      {pdfUrl && <PdfViewPopup url={pdfUrl} onClose={() => setPdfUrl(null)} />}
    </>
  );
};

export default PdfViewButton;
