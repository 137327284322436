import { useState, useEffect, ReactElement } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { renderSnackbar } from 'utils';
import BARCODE_TYPE, { EAN_TYPE, UPC_TYPE } from 'enums/barcode-type.enums';
/* Components */
import { Dialog, ClassNameMap, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

/* Library not supported for TS */
// @ts-ignore
import Quagga from 'quagga';
import './styles.css';

const EAN_TYPES = [EAN_TYPE.EAN_8, EAN_TYPE.EAN_13];
const UPC_TYPES = [UPC_TYPE.UPC_E, UPC_TYPE.UPC_A];

/* README */
/*** 
 * This hook is used for initing Quagga scanner
 * Need to specify element before calling
 * (
 *    <div className={"container"}>
 *      <div 
 *        id="interactive" 
 *        className={"viewport"}
 *      />
 *    </div>
 * )
 * Parameter:
 *    onChangeSuccess: a callback function invoked when code detected successfully
 *    onInitSuccess: a call back function invoked when camera device start successfully
 * Response
 *    initQuagga: Function to start scanning (Type EAN or UPC)
 *    onDestroy: Function to stop scanning
 *    codeDetectedTimer: Timer for detection
 * Note: If init quagga (inited = true), need to onDestroy when destructuring
 * (If not do this, camera will not be stopped)
/* End */

const useBarcodeScanner = (
  onDetected: (code: string) => void,
  config: {
    onInitSuccess?: () => void;
    onInitError?: (errorCode?: number) => void;
    customViewportId?: string;
    disabledSnackbar?: boolean;
  } = {},
): [
  renderScannerPopup: () => ReactElement | null,
  activateScanner: (type?: BARCODE_TYPE) => void,
  onDestroy: () => void,
] => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  const {
    onInitSuccess = () => {},
    onInitError = () => {},
    customViewportId = 'interact',
    disabledSnackbar,
  } = config;

  const [barcodeType, setBarcodeType] = useState<BARCODE_TYPE>(
    BARCODE_TYPE.EAN,
  );
  const [inited, setInited] = useState<boolean>(false);
  const [openScannerPopup, setOpenScannerPopup] = useState<boolean>(false);

  const initQuagga = (): void => {
    try {
      Quagga.init(
        {
          inputStream: {
            type: 'LiveStream',
            constraints: {
              width: 1920,
              height: 1080,
              facingMode: 'environment',
              aspectRatio: { min: 1, max: 2 },
            },
            target: document.getElementById(customViewportId),
          },
          locator: {
            patchSize: 'medium',
            halfSample: true,
          },
          numOfWorkers: 4,
          frequency: 20,
          locate: true,
          decoder: {
            readers: [
              'ean_8_reader',
              'upc_e_reader',
              'upc_reader',
              'ean_reader',
            ],
          },
        },
        (err: any) => {
          if (err) {
            if (!disabledSnackbar) {
              switch (err.code) {
                case 8:
                  renderSnackbar(
                    t('barcode_scanner:requested_device_not_found'),
                  );
                  break;
                default:
                  renderSnackbar(t('barcode_scanner:cant_access_your_camera'));
              }
            }
            setOpenScannerPopup(false);
            onInitError !== undefined && onInitError(err.code);
            return;
          }
          setInited(true);
          Quagga.start();
          onInitSuccess !== undefined && onInitSuccess();
        },
      );

      Quagga.onProcessed((result: any) => {
        var drawingCtx = Quagga.canvas.ctx.overlay,
          drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(
              0,
              0,
              parseInt(drawingCanvas.getAttribute('width')),
              parseInt(drawingCanvas.getAttribute('height')),
            );
            result.boxes
              .filter(function (box: any) {
                return box !== result.box;
              })
              .forEach(function (box: any) {
                Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                  color: 'green',
                  lineWidth: 2,
                });
              });
          }

          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
              color: '#00F',
              lineWidth: 2,
            });
          }

          if (result.codeResult && result.codeResult.code) {
            Quagga.ImageDebug.drawPath(
              result.line,
              { x: 'x', y: 'y' },
              drawingCtx,
              { color: 'red', lineWidth: 3 },
            );
          }
        }
      });

      Quagga.onDetected((result: any) => {
        if (result.codeResult?.code) {
          switch (barcodeType) {
            case BARCODE_TYPE.EAN:
              if (UPC_TYPES.includes(result.codeResult.format)) {
                /* Handle incorrect format error here */
              }
              break;
            case BARCODE_TYPE.UPC:
              if (EAN_TYPES.includes(result.codeResult.format)) {
                /* Handle incorrect format error here */
              }
              break;
            default:
              renderSnackbar(t('common:something_wrong'));
          }
          onDetected(result.codeResult.code);
          Quagga.stop();
          onClose();
        }
      });
    } catch (err: any) {
      if (!disabledSnackbar)
        renderSnackbar(t('barcode_scanner:cant_access_your_camera'));
      setOpenScannerPopup(false);
      onInitError !== undefined && onInitError(err.code);
    }
  };

  const onDestroy = (): void => {
    if (inited) Quagga.stop();
  };

  const activateScanner = (type: BARCODE_TYPE = BARCODE_TYPE.EAN): void => {
    setOpenScannerPopup(true);
    setBarcodeType(type);
  };

  const onClose = (): void => {
    onDestroy();
    setOpenScannerPopup(false);
  };

  const renderScannerPopup = (): ReactElement | null => {
    if (!openScannerPopup) return null;
    return (
      <Dialog
        disableEnforceFocus
        open={true}
        onClose={onClose}
        maxWidth="lg"
        fullScreen={true}
        style={!inited ? { display: 'none' } : {}}
      >
        <DialogTitle className={classes.scannerPopupTitle}>
          {t('barcode_scanner:barcode_scanner')}
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={'barcode-scanner-popup-container'}>
          <div
            id={customViewportId}
            className={'barcode-scanner-popup-viewport'}
          />
        </div>
      </Dialog>
    );
  };

  useEffect(() => {
    if (openScannerPopup) {
      /* Waiting for render HTML element*/
      setTimeout(() => {
        initQuagga();
      }, 200);
    }
  }, [openScannerPopup]);

  return [renderScannerPopup, activateScanner, onDestroy];
};

export default useBarcodeScanner;
