import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  scannerIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
