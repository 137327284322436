import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tableRiskRating: {
    '& .MuiTableCell-root': {
      padding: '0px',
    },
  },
  makeStylesRiskLevel: {
    border: '1px black solid!important',
    minWidth: '15px',
    maxHeight: '15px',
    textAlign: 'center!important',
    boxSizing: 'border-box',
    color: theme.palette.colors.text,
  },
}));

export default useStyles;
