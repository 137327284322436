import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  selectInput: {
    width: '185px',
    '& .MuiMenuItem-root': {
      fontSize: '14px',
    },
  },
  selectMenu: {
    height: 'auto',
  },
  selectMenuMobile: {
    height: '350px',
  },
}));

export default useStyles;
