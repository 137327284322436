import axios, { InternalAxiosRequestConfig } from 'axios';
import { BASE_API_URL } from '..';

const accessToken = localStorage.getItem('access_token');

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  timeout: 50000,
  headers: {
    Authorization: accessToken ? 'JWT ' + accessToken : null,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    let accessToken = localStorage.getItem('access_token');
    config.headers['Authorization'] = accessToken ? `JWT ${accessToken}` : null;
    return config;
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) return Promise.reject({ response: { status: 408 } });
    return Promise.reject(error);
  }
);

export default axiosInstance;
