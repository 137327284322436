import { Fragment, ReactElement, useEffect, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'hooks/useViewport';
import { getSdsDetail } from 'api/substances';
import { isMobile } from 'utils';
import SDS_STATEMENTS_TABLE_TYPE from 'enums/sds-statements-table-type.enum';
import {
  HeaderLiteralResponse,
  LiteralInterface,
  SDSInfoCode,
  SDSOtherInfo,
} from 'interfaces';
import {
  GetSdsDetailResponse,
  GetSdsDetailResponseDto,
  SDSDetailExtractedData,
  SDSDetailIcon,
  SdsFieldItem,
  SdsFieldItemKeyType,
  SdsSectionInformationItem,
} from 'interfaces/global-search-sds';
import { SdsSectionItem, SdsSectionStatementItem } from 'interfaces/substance';
import { BACKEND_URL } from 'api';
/* Components */
import {
  ClassNameMap,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import SDSDetailHeader from '../header';
import SDSDetailSubHeader from '../subheader';
import StatementsTable from '../statements-table';
import CustomLoader from 'components/loader';

const SDS_SECTION_FIELDS: Array<SdsSectionItem> = [
  {
    key: 'general_info',
    fields: [
      {
        key: 'sds_pdf_product_name',
        tag: '#SDS_PDF_PRODUCT_NAME',
        headerKey: 'product_name',
        defaultHeaderValue: 'Product Name',
        needTranslated: true,
      },
      {
        key: 'chemical_name',
        tag: '#CHEMICAL_NAME',
        headerKey: 'chemical_name',
        defaultHeaderValue: 'Chemical Name',
        needTranslated: true,
      },
      {
        key: 'substance_name',
        tag: '#SUBSTANCE_NAME',
        headerKey: 'substance_name',
        defaultHeaderValue: 'Substance Name',
        needTranslated: true,
      },
      {
        key: 'trade_name',
        tag: '#TRADE_NAME',
        headerKey: 'trade_name',
        defaultHeaderValue: 'Trade Name',
        needTranslated: true,
      },
      {
        key: 'chemical_name_synonyms',
        tag: '#SYNONYM_NAME',
        headerKey: 'chemical_name_synonyms',
        defaultHeaderValue: 'Chemical Name Synonyms',
        needTranslated: true,
      },
      {
        key: 'product_identifier',
        tag: '#PRODUCT_IDENTIFIER',
        headerKey: 'product_identifier',
        defaultHeaderValue: 'Product Identifier',
        needTranslated: true,
      },
      {
        key: 'material_name',
        tag: '#MATERIAL_NAME',
        headerKey: 'material_name',
        defaultHeaderValue: 'Material Name',
        needTranslated: true,
      },
      {
        key: 'product_group',
        tag: '#PRODUCT_GROUP',
        headerKey: 'product_group',
        defaultHeaderValue: 'Product group',
        needTranslated: true,
      },
      {
        key: 'chemical_formula',
        tag: '#CHEMICAL_FORMULA',
        headerKey: 'chemical_formula',
        defaultHeaderValue: 'Chemical formula',
        needTranslated: true,
      },
      {
        key: 'chemical_family',
        tag: '#CHEMICAL_FAMILY',
        headerKey: 'chemical_family',
        defaultHeaderValue: 'Chemical family',
        needTranslated: true,
      },
      {
        key: 'product_code',
        tag: '#PRODUCT_CODE',
        headerKey: 'product_code',
        defaultHeaderValue: 'Product code',
        needTranslated: true,
      },
      {
        key: 'cas_no',
        tag: '#CAS_NO',
        headerKey: 'cas_no',
        defaultHeaderValue: 'CAS №',
        needTranslated: true,
      },
      {
        key: 'ec_no',
        headerKey: 'ec_no',
        tag: '#EC_NO',
        defaultHeaderValue: 'EC №',
        needTranslated: true,
      },
      {
        key: 'sds_no',
        tag: '#SDS_NO',
        defaultHeaderValue: 'SDS №',
        needTranslated: false,
      },
      {
        key: 'reach_reg_no',
        headerKey: 'reach_no',
        tag: '#REACH_REG_NO',
        defaultHeaderValue: 'REACH reg. №',
        needTranslated: false,
      },
      {
        key: 'einecs',
        tag: '#EINECS',
        defaultHeaderValue: 'EINECS. №',
        needTranslated: false,
      },
      {
        key: 'ufi_no',
        tag: '#UFI_NO',
        defaultHeaderValue: 'UFI. №',
        needTranslated: false,
      },
      {
        key: 'revision_date',
        tag: '#REVISION_DATE',
        headerKey: 'revision_date',
        defaultHeaderValue: 'Revision date',
        needTranslated: true,
      },
      {
        key: 'published_date',
        tag: '#PUBLISHED_DATE',
        headerKey: 'published_date',
        defaultHeaderValue: 'Published date',
        needTranslated: true,
      },
      {
        key: 'printed_date',
        tag: '#PRINTED_DATE',
        headerKey: 'printed_date',
        defaultHeaderValue: 'Printed date',
        needTranslated: true,
      },
      {
        key: 'issue_date',
        tag: '#ISSUED_DATE',
        headerKey: 'issue_date',
        defaultHeaderValue: 'Issued date',
        needTranslated: true,
      },
      {
        key: 'intented_use',
        tag: '#INTENDED_USE',
        headerKey: 'intented_use',
        defaultHeaderValue: 'Intented date',
        needTranslated: true,
      },
      {
        key: 'used_advised_against',
        tag: '#USE_ADVISED_AGAINST',
        headerKey: 'used_advised_against',
        defaultHeaderValue: 'Uses advised against',
        needTranslated: true,
      },
      {
        key: 'version',
        tag: '#VERSION',
        headerKey: 'version',
        defaultHeaderValue: 'Version',
        needTranslated: true,
      },
      {
        key: 'revision',
        tag: '#REVISION',
        headerKey: 'revision',
        defaultHeaderValue: 'Revision',
        needTranslated: true,
      },
      {
        key: 'replacing_sds_id',
        headerKey: 'previous_version',
        defaultHeaderValue: 'Previous version',
        needTranslated: true,
      },
      {
        key: 'replaced_by_id',
        headerKey: 'newer_version',
        defaultHeaderValue: 'Newer version',
        needTranslated: true,
      },
      {
        key: 'iso_pictogram',
        headerKey: 'icons_in_pdf',
        defaultHeaderValue: 'Icons in PDF',
        needTranslated: true,
      },
    ],
  },
  {
    key: 'company_information',
    fields: [
      {
        key: 'company_name_supplier_sds',
        tag: '#COMPANY_NAME_SUPPLIER_SDS',
        headerKey: 'company_name_supplier_sds',
        defaultHeaderValue: 'Supplier of SDS',
        needTranslated: true,
      },
      {
        key: 'company_name_distributor',
        tag: '#COMPANY_NAME_DISTRIBUTOR',
        headerKey: 'company_name_distributor',
        defaultHeaderValue: 'Distributor Name',
        needTranslated: true,
      },
      {
        key: 'producer_of_sds_company_name',
        tag: '#COMPANY_NAME',
        headerKey: 'producer_of_sds_company_name',
        defaultHeaderValue: 'Company Name',
        needTranslated: true,
      },
      {
        key: 'manufacturer_company_name',
        tag: '#COMPANY_NAME_MANUFACTURER',
        headerKey: 'manufacturer_company_name',
        defaultHeaderValue: 'Manufacturer Name',
        needTranslated: true,
      },
      {
        key: 'address',
        tag: '#ADDRESS',
        defaultHeaderValue: 'Address',
        needTranslated: false,
      },
      {
        key: 'company_web_site',
        tag: '#WEBSITE',
        headerKey: 'company_web_site',
        editedKey: 'website',
        defaultHeaderValue: 'Company website',
        needTranslated: true,
      },
      {
        key: 'email',
        tag: '#EMAIL',
        headerKey: 'email',
        defaultHeaderValue: 'Email',
        needTranslated: true,
      },
      {
        key: 'telephone',
        tag: '#TELEPHONE',
        headerKey: 'telephone',
        defaultHeaderValue: 'Telephone',
        needTranslated: true,
      },
      {
        key: 'emergency_telephone_number',
        tag: '#EMERGENCY_TELEPHONE_NUMBER',
        headerKey: 'emergency_telephone_number',
        defaultHeaderValue: 'Emergency telephone number',
        needTranslated: true,
      },
    ],
  },
  {
    key: 'ghs_information',
    fields: [
      {
        key: 'signal_word',
        tag: '#2_2_3_SIGNALWORD',
        headerKey: 'signal_word',
        defaultHeaderValue: 'Signal word',
        needTranslated: true,
      },
      {
        key: 'hazard_category_abbreviations',
        tag: '#HAZARD_CATEGORY_ABBREVIATIONS',
        headerKey: 'hazard_category_abbreviations',
        defaultHeaderValue: 'Hazard category abbreviations',
        needTranslated: true,
      },
    ],
  },
  {
    type: 'statements_table',
    key: 'hazard_codes',
    titleKey: 'hazard_statements',
    tag: SDS_STATEMENTS_TABLE_TYPE.HAZARD,
  },
  {
    type: 'statements_table',
    key: 'precautionary_codes',
    titleKey: 'precautionary_statements',
    tag: SDS_STATEMENTS_TABLE_TYPE.PECAUTIONARY,
  },
  {
    type: 'statements_table',
    key: 'euh_codes',
    titleKey: 'euh_statements',
    tag: SDS_STATEMENTS_TABLE_TYPE.EUH,
  },
];
const SDS_OTHER_SECTION_KEYS = [
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
];

interface SDSDetailPopupProps {
  sdsId: number;
  literal: LiteralInterface | null;
  onClose: () => void;
  toSection?: number;
}

const SDSDetailPopup = ({
  sdsId,
  literal,
  onClose,
  toSection,
}: SDSDetailPopupProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  const { width } = useViewport();
  const [sds, setSds] = useState<GetSdsDetailResponseDto | null>(null);
  const parsedData = {
    ...(sds?.extracted_data ?? {}),
    ...(sds ?? {}),
  };

  const renderValue = (
    value: string | Array<string> | null,
    key?: string,
  ): ReactElement | null => {
    const valueKey = key ? `${key}_line_` : '';
    if (!value) return null;
    return (
      <span>
        {Array.isArray(value)
          ? value.map((row: string, index: number) => {
              return (
                <Fragment key={`${valueKey}${index}`}>
                  {row}
                  <br />
                </Fragment>
              );
            })
          : typeof value === 'string'
            ? value.split('\n').map((row: string, index: number) => (
                <Fragment key={`${valueKey}${index}`}>
                  {row}
                  <br />
                </Fragment>
              ))
            : value}
      </span>
    );
  };

  const renderFieldValue = (key: SdsFieldItemKeyType): ReactElement | null => {
    if (!sds) return null;

    if (key === 'iso_pictogram') {
      if (!parsedData.iso_icon) return null;
      return (
        <div className={classes.fieldIconValue}>
          {parsedData.iso_icon.map((icon: SDSDetailIcon, index: number) => (
            <img
              key={`section_field_${key}_${index}`}
              src={`${BACKEND_URL}/${icon.link}`}
              alt={icon.icon_no ?? ''}
              title={icon.title ?? ''}
              width={32}
              height={32}
            />
          ))}
        </div>
      );
    }

    const value = parsedData[key] as string;
    if (!value) return null;
    return (
      <Typography className={classes.fieldValue}>
        {' '}
        {renderValue(parsedData[key] as string, key as string)}{' '}
      </Typography>
    );
  };

  const renderSection = (section: SdsSectionItem): ReactElement | null => {
    if (!sds) return null;

    return (
      <div key={`section_${section.key}`} id={`section_${section.key}`}>
        <div className={classes.sectionTitle}>
          <Typography className={classes.sectionTitleText}>
            {t(
              `sds_detail:${
                (section as SdsSectionStatementItem).titleKey
                  ? (section as SdsSectionStatementItem).titleKey
                  : section.key
              }`,
            )}
          </Typography>
        </div>
        {(section as SdsSectionStatementItem).type === 'statements_table' ? (
          <StatementsTable
            codes={
              parsedData[
                section.key as keyof SDSDetailExtractedData
              ] as Array<SDSInfoCode>
            }
            sectionKey={`section_${section.key}`}
          />
        ) : (
          (section as SdsSectionInformationItem).fields.map(field =>
            renderField(field),
          )
        )}
      </div>
    );
  };

  const renderField = (field: SdsFieldItem): ReactElement | null => {
    const { needTranslated, key, headerKey, defaultHeaderValue } = field;

    if (!sds) return null;

    let label = defaultHeaderValue;
    if (needTranslated) {
      if (
        literal?.header_literal &&
        literal.header_literal[headerKey as keyof HeaderLiteralResponse]
      )
        label =
          literal.header_literal &&
          literal.header_literal[headerKey as keyof HeaderLiteralResponse];
      else label = t(`sds_detail:${headerKey}`);
    }

    const value = renderFieldValue(key);
    if (!value) return null;

    return (
      <div
        key={`section_field_${field.key}`}
        className={classes.fieldContainer}
      >
        <Typography className={classes.fieldLabel}>{label}</Typography>
        {value}
      </div>
    );
  };

  const renderOtherSectionFieldValue = (
    field: SDSOtherInfo,
    key: string,
  ): ReactElement => {
    if (
      (key === '3' && field.tag === '#3_HEADER_COMPOSITION') ||
      (key === '14' && field.tag === '#14_HEADER_TRANSPORT_INFO')
    ) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: field.value,
          }}
        />
      );
    }

    return (
      <div className={classes.fieldValue}>
        {renderValue(field.value, field.tag)}
      </div>
    );
  };

  const renderOtherSectionField = (
    field: SDSOtherInfo,
    sectionKey: string,
  ): ReactElement => {
    let label = field.user_literal ?? field.default_literal ?? field.tag;
    if (
      literal?.tag_literal &&
      field.tag in literal.tag_literal &&
      literal.tag_literal[field.tag]
    )
      label = literal.tag_literal[field.tag];

    return (
      <div
        key={`section_${sectionKey}_field_${field.tag}`}
        className={classes.fieldContainer}
      >
        <Typography className={classes.fieldLabel}>{label}</Typography>
        {renderOtherSectionFieldValue(field, sectionKey)}
      </div>
    );
  };

  const renderOtherSection = (key: string): ReactElement | null => {
    if (!sds) return null;

    let sectionTitle = `${t('sds_detail:section')} ${key}`;
    if (literal?.section_header && literal.section_header[Number(key)])
      sectionTitle = literal.section_header[Number(key)];

    const data = sds.other_data?.[key];
    return (
      <div key={`section_${key}`} id={`section_${key}`}>
        <div className={classes.sectionTitle}>
          <Typography className={classes.sectionTitleText}>
            {sectionTitle}
          </Typography>
        </div>
        {data && data.map(field => renderOtherSectionField(field, key))}
      </div>
    );
  };

  useEffect(() => {
    getSdsDetail(sdsId).then((response: GetSdsDetailResponse) => {
      setSds(response.data);
      if (toSection) {
        setTimeout(() => {
          document
            .getElementById(`section_${toSection}`)
            ?.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    });
  }, []);

  return (
    <Dialog
      maxWidth="lg"
      onClose={() => onClose()}
      open={true}
      disableEnforceFocus
      fullScreen={isMobile() || width < 1024}
      className={classes.dialogContainer}
    >
      <DialogTitle
        sx={{
          padding: 0,
          paddingBottom: '4px',
        }}
      >
        <div className={classes.dialogTitleContainer}>
          <div className={classes.closeIcon} onClick={() => onClose()}>
            <CloseIcon fontSize="small" />
          </div>
        </div>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: 0,
          background: '#fff',
        }}
      >
        {!literal || !sds ? (
          <CustomLoader />
        ) : (
          <div
            className={classes.contentWrapper}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className={classes.content}>
              <SDSDetailHeader
                signalWord={parsedData.signal_word ?? ''}
                substanceName={parsedData.sds_pdf_product_name ?? ''}
                substanceSupplier={parsedData.sds_pdf_manufacture_name ?? ''}
                pdfUrl={parsedData.link_to_public_view}
              />
              <SDSDetailSubHeader
                pictogram={parsedData.ghs_pictograms ?? []}
                risk={{
                  healthRisk: parsedData.health_risk ?? null,
                  safetyRisk: parsedData.safety_risk ?? null,
                  environmentalRisk: parsedData.environment_risk ?? null,
                }}
              />
              <div className={classes.infoContainer}>
                {SDS_SECTION_FIELDS.map(section => renderSection(section))}
                {SDS_OTHER_SECTION_KEYS.map(section =>
                  renderOtherSection(section),
                )}
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SDSDetailPopup;
