import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.colors.primary,
    padding: '10px 0px 15px 0px',
  },
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  pdfIcon: {
    marginLeft: '10px',
    marginRight: '10px',
    cursor: 'pointer',
    color: theme.palette.colors.white,
    padding: '0px',
  },
  emptyBlock: {
    paddingTop: 5,
    paddingRight: 10,
    paddingLeft: 10,
    width: 20,
    height: 20,
  },
  signalWordContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    margin: '-30px 15px 35px 15px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px 15px 0px 15px',
    },
  },
  signalWordValue: {
    fontSize: '150%',
    color: theme.palette.colors.white,
    fontWeight: 'bold',
    left: '0px',
    top: '0px',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'unset',
    },
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  heading: {
    color: theme.palette.colors.white,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '0 10px',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subHeading: {
    color: theme.palette.colors.white,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '0 10px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

export default useStyles;
