import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 20px',
    background: '#F2F5F7',
    minHeight: 'calc(100vh - 10px)',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '10px',
    marginTop: '10px',
  },
  headerActionGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  actionIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    border: '1px solid rgba(255, 255, 255, 1)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '30px 10px',
    },
    '& .globalSearchInput': {
      width: '60%',
      flex: 'unset',
    },
    [theme.breakpoints.down('lg')]: {
      '& .globalSearchInput': {
        flex: '1',
        width: '80%',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .globalSearchInput': {
        width: '100%',
      },
    },
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  searchBarContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: 0,
    },
  },
  searchBarContent: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  advancedSearchBarContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '7px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  advancedSearchBtn: {
    width: '185px',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
    },
  },
  advancedSearchFilter: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  advancedSearchSelectWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  advancedSearchSelectGroup: {
    display: 'flex',
    gap: 15,
    [theme.breakpoints.down('sm')]: {
      '& .MuiFormControl-root': {
        flex: 1,
        '& .MuiInput-root': {
          width: '100%',
        },
      },
    },
  },
  searchIcon: {
    padding: 0,
    backgroundColor: '#1570EF',
    '&:hover': {
      backgroundColor: '#444CAE',
    },
  },
  inUseChecbox: {
    display: 'flex',
    alignItems: 'center',
  },
  resultContainer: {
    margin: '15px 0px 20px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  resultContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  inUseRowColor: {
    backgroundColor: 'rgb(229 246 253 / 84%)',
  },
  inUseRowText: {
    fontSize: 10,
    margin: 0,
    position: 'absolute',
    bottom: 17,
    transform: 'translate(-50%, -50%)',
    height: 0,
    whiteSpace: 'nowrap',
  },
  loadingContent: {
    height: '432px',
    [theme.breakpoints.down('sm')]: {
      height: '200px',
    },
  },
  errorText: {
    fontSize: '13px',
    color: '#48464B',
    fontWeight: 600,
    '& span': {
      backgroundColor: 'yellow',
    },
  },
  rowAction: {
    display: 'flex',
    justifyContent: 'left',
    fontSize: 14,
    minWidth: '124px',
    flex: 1,
    gap: 7,
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0',
      width: '90%',
      justifyContent: 'right',
    },
  },
}));

export default useStyles;
