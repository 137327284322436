const SDS_INVENTORY_URL = 'https://inventory.sdsmanager.com';
const SDS_INVENTORY_RC_URL = 'https://rc-inventory.sdsmanager.com';
const SDS_INVENTORY_STAGING_URL = 'https://staging-inventory.sdsmanager.com';

export const getEnv = (): string => {
  const host = window.location.hostname;
  if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1)
    return 'development';
  if (host.indexOf('staging-app.sdsmanager.com') > -1) return 'staging';
  if (host.indexOf('rc-app.sdsmanager.com') > -1) return 'rc';

  return 'production';
};

const workingEnv = getEnv();

export const BACKEND_URL =
  workingEnv === 'production'
    ? SDS_INVENTORY_URL
    : workingEnv === 'rc'
      ? SDS_INVENTORY_RC_URL
      : workingEnv === 'staging'
        ? SDS_INVENTORY_STAGING_URL
        : 'http://localhost:8000';

export const INVENTORY_URL =
  workingEnv === 'production'
    ? SDS_INVENTORY_URL
    : workingEnv === 'rc'
      ? SDS_INVENTORY_RC_URL
      : workingEnv === 'staging'
        ? SDS_INVENTORY_STAGING_URL
        : 'http://localhost:3001';

export const BASE_API_URL = `${BACKEND_URL}/api`;

export const CACHE_NAME = 'sds-manager-cache';
