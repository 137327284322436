import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import useStyles from './styles';
/* Components */
import { Input, ClassNameMap } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';

interface SearchBarProps {
  id: string;
  placeHolder?: string;
  defaultValue?: string;
  searchValue?: string;
  onChange?: (text: string) => void;
  onSearch: (text: string) => void;
  onClear?: () => void;
  wrapperStyle?: any;
  moreOption?: ReactElement;
}

const SearchBar = ({
  id,
  placeHolder,
  defaultValue,
  searchValue,
  onChange,
  onSearch,
  onClear,
  wrapperStyle = {},
}: SearchBarProps) => {
  const classes: ClassNameMap = useStyles();
  const [value, setValue] = useState<string>(defaultValue ? defaultValue : '');

  const onTypeSearch = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch(value);
    }
  };

  useEffect(() => {
    setValue(searchValue ?? '');
  }, [searchValue]);

  return (
    <div className={classes.searchWrapper} style={wrapperStyle}>
      <Input
        className={classes.searchInput}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
          if (onChange) onChange(e.target.value);
        }}
        id={id}
        onKeyDown={onTypeSearch}
        placeholder={placeHolder}
        inputProps={{ maxLength: 255 }}
        sx={{
          '& input::placeholder': {
            fontSize: 13,
          },
        }}
      />

      {value && onClear ? (
        <Clear
          className={classes.clearTextButton}
          onClick={() => {
            if (!value) return;
            onClear();
            setValue('');
          }}
        />
      ) : null}

      <div className={classes.searchIcon}>
        <Search
          onClick={() => onSearch(value)}
          height={20}
          width={20}
          style={{
            color: '#626DF9',
            cursor: 'pointer',
          }}
        />
      </div>
    </div>
  );
};

export default SearchBar;
