import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'utils';
/* Components */
import { Alert, ClassNameMap, Typography } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

const NoAccess = () => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();

  return (
    <div className={classes.container}>
      <Alert
        severity="info"
        color="warning"
        sx={{
          width: isMobile() ? 'auto' : '700px',
          borderRadius: '5px',
          border: '2px solid #b0840c',
          padding: '10px',
        }}
        icon={<InfoIcon fontSize="inherit" sx={{ color: '#b0840c' }} />}
      >
        <Typography
          sx={{ fontSize: '16px', color: 'black', fontWeight: '600' }}
        >
          {t('common:offline_app_access_disabled_by_admin')}
        </Typography>
      </Alert>
    </div>
  );
};

export default NoAccess;
