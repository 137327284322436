import { ReactElement, useEffect, useState } from 'react';
import useStyles from './styles';
import { Trans, useTranslation } from 'react-i18next';
import { getAllPdfs } from 'api/substances';
import { isMobile, renderSnackbar, trimString } from 'utils';
import APP_PAGE from 'enums/page.enums';
import {
  AdvancedSearchValues,
  FilterOption,
  GetAllPdfsResponse,
  GetAllPdfsResponseDto,
  SearchResult,
  TableColumn,
} from 'interfaces/global-search-sds';
import { LiteralInterface } from 'interfaces';
/* Components */
import {
  Checkbox,
  ClassNameMap,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Home as HomeIcon,
  ArrowBackIos as ArrowBackIcon,
  Launch as LaunchIcon,
  ShareRounded,
} from '@mui/icons-material';
import SearchIcon from 'assets/search-icon';
import CustomTooltip from 'components/custom-tooltip';
import SearchBar from 'components/search-bar/global-search';
import AdvancedSearchBar from 'components/search-bar/global-advanced-search';
import AdvancedSearchButton from 'components/search-bar/advanced-search-button';
import LanguageSelect from 'components/filter/language-select';
import RevisionYearSelect from 'components/filter/revision-year-select';
import RegionSelect from 'components/filter/region-select';
import EnhancedTable from 'components/table';
import PdfViewButton from 'components/pdf-view-button';
import CustomLoader from 'components/loader';
import CardTableMobile from 'components/table-mobile';
import SDSDetailPopup from 'components/popup/sds-detail/sds-detail-popup';
import IconStyled from 'components/icons-styled';

interface GlobalSearchSDSProps {
  literal: LiteralInterface | null;
  onSwitchPage: (name: APP_PAGE) => void;
  defaultSearch: string;
}

const GlobalSearchSDS = ({
  literal,
  onSwitchPage,
  defaultSearch,
}: GlobalSearchSDSProps) => {
  const classes: ClassNameMap = useStyles();
  const { t } = useTranslation();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [advancedSearch, setAdvancedSearch] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('all');
  const [regions, setRegions] = useState<Array<FilterOption<string>>>([]);
  const [minYear, setMinYear] = useState<string>('all');
  const [searchInUse, setSearchInUse] = useState<boolean>(false);
  const [animationCounter, setAnimationCounter] = useState<number>(0);
  const [searchValues, setSearchValues] = useState<AdvancedSearchValues>({
    keyword: defaultSearch,
    productName: '',
    supplierName: '',
    productCode: '',
    casNo: '',
  });
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [startSearch, setStartSearch] = useState<boolean>(false);
  const [data, setData] = useState<SearchResult>({
    results: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | ReactElement>('');
  const [sdsDetailPopup, setSdsDetailPopup] = useState<ReactElement | null>(
    null,
  );

  const TABLE_COLUMNS: Array<TableColumn> = [
    {
      id: 'sds_pdf_product_name',
      title: 'common:product_name',
    },
    {
      id: 'product_code',
      title: 'common:product_code',
    },
    {
      id: 'sds_pdf_manufacture_name',
      title: 'common:supplier_name',
    },
    {
      id: 'cas_no',
      title: 'common:ec_cas',
      width: '15%',
    },
    {
      id: 'sds_pdf_revision_date',
      title: 'common:revision_date',
    },
    {
      id: 'regulation_area',
      title: 'common:regulation_area',
    },
    {
      id: 'language',
      title: 'common:language',
    },
    {
      id: 'actions',
      title: 'common:actions',
      width: '5%',
    },
  ];
  const filterCount =
    (language && language !== 'all' ? 1 : 0) +
    (minYear && minYear !== 'all' ? 1 : 0) +
    (regions && regions.length > 0 ? 1 : 0) +
    (searchInUse ? 1 : 0);

  const onGlobalSearchSDS = (value?: AdvancedSearchValues): void => {
    setStartSearch(true);

    const searchParam = value ?? searchValues;
    let enoughSearchLength;
    if (!advancedSearch)
      enoughSearchLength = searchParam && searchParam.keyword.length >= 3;
    else
      enoughSearchLength =
        [
          searchParam.productName,
          searchParam.supplierName,
          searchParam.productCode,
        ].some(val => val.length >= 3) || searchParam.casNo.length >= 4;

    if (!enoughSearchLength) {
      setData({
        results: [],
        count: 0,
      });
      setErrorText(
        t('global_search_sds:search_must_contain_at_lease_3_character'),
      );
      return;
    }
    setLoading(true);

    const getAllPdfsRequest = getAllPdfs({
      search_product: advancedSearch
        ? searchParam.productName
        : (searchParam.keyword ?? ''),
      search_producer: advancedSearch ? searchParam.supplierName : '',
      search_product_code: advancedSearch ? searchParam.productCode : null,
      search_cas_no: advancedSearch ? searchParam.casNo : null,
      page: page + 1,
      page_size: rowsPerPage,
      language_code: language !== 'all' ? language : null,
      minimum_year: minYear === 'all' ? '' : minYear,
      region:
        regions && regions.length > 0 ? regions.map(item => item.value) : null,
      is_in_use_by_customer: searchInUse,
      advanced_search: advancedSearch ? true : null,
    });
    getAllPdfsRequest.then((response: GetAllPdfsResponse) => {
      setLoading(false);
      if (response.status === 200) {
        setData(response.data);
        if (response.data.results.length === 0 || response.data.count === 0) {
          const searchText = trimString(
            `${searchParam.productName ?? ''} ${searchParam.supplierName ?? ''} ${searchParam.productCode ?? ''} ${searchParam.casNo ?? ''}`,
          );
          setErrorText(
            searchInUse ? (
              <Trans
                ns={'global_search_sds'}
                i18nKey={'dis_not_match_any_sds_in_your_library'}
                values={{
                  keyword: advancedSearch
                    ? !!searchText.trim()
                      ? searchText
                      : ''
                    : searchParam.keyword,
                }}
                components={[<span />]}
              />
            ) : (
              <Trans
                ns={'global_search_sds'}
                i18nKey={'did_not_match_any_sds'}
                values={{
                  keyword: advancedSearch
                    ? !!searchText.trim()
                      ? searchText
                      : ''
                    : searchParam.keyword,
                }}
                components={[<span />]}
              />
            ),
          );
        } else setErrorText('');
      } else {
        if (response.status === 408)
          setErrorText(t('common:no_internet_connection'));
        else setErrorText(t('common:failed_to_fetch_data'));
      }
    });
  };

  const renderRowActions = (row: GetAllPdfsResponseDto): ReactElement => {
    const ACTION_STYLE = {
      fontSize: '20px',
      width: '20px',
      height: '20px',
      zIndex: 1,
    };
    const ACTION_IMG_STYLE = {
      width: 18,
      height: 18,
      margin: 1,
    };
    const ACTION_LIST = [
      {
        content: (
          <PdfViewButton
            styled={false}
            url={row.link_to_public_view}
            cachePdf={false}
          />
        ),
        id: 'view_pdf',
      },
      {
        content: (
          <LaunchIcon
            style={ACTION_STYLE}
            onClick={e => {
              if (!row.id) return;
              e.preventDefault();
              e.stopPropagation();
              setSdsDetailPopup(
                <SDSDetailPopup
                  sdsId={row.id as number}
                  literal={literal}
                  onClose={() => setSdsDetailPopup(null)}
                />,
              );
            }}
          />
        ),
        id: 'view_details',
        title: t('global_search_sds:view_details'),
      },
      {
        content: (
          <ShareRounded
            style={ACTION_STYLE}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (!row.link_to_public_view) {
                renderSnackbar(
                  t(
                    'global_search_sds:this_substance_does_not_have_a_sds_file',
                  ),
                );
                return;
              }
              navigator.clipboard.writeText(row.link_to_public_view ?? '');
              renderSnackbar(t('global_search_sds:url_for_pdf_view'));
            }}
          />
        ),
        id: 'share_pdf',
        title: row.link_to_public_view ? t('global_search_sds:share_pdf') : '',
      },
      {
        content: (
          <div style={ACTION_STYLE}>
            <img
              alt="e-medical-attention"
              style={ACTION_IMG_STYLE}
              src={'/images/e-medical-attention.png'}
              onClick={e => {
                if (!row.id) return;
                e.preventDefault();
                e.stopPropagation();
                setSdsDetailPopup(
                  <SDSDetailPopup
                    sdsId={row.id as number}
                    literal={literal}
                    onClose={() => setSdsDetailPopup(null)}
                    toSection={4}
                  />,
                );
              }}
            />
          </div>
        ),
        id: 'e-medical-attention',
        title: `${t('sds_detail:section')} 4`,
      },
      {
        content: (
          <div style={ACTION_STYLE}>
            <img
              alt="F004"
              style={ACTION_IMG_STYLE}
              src={'/images/F004.png'}
              onClick={e => {
                if (!row.id) return;
                e.preventDefault();
                e.stopPropagation();
                setSdsDetailPopup(
                  <SDSDetailPopup
                    sdsId={row.id as number}
                    literal={literal}
                    onClose={() => setSdsDetailPopup(null)}
                    toSection={5}
                  />,
                );
              }}
            />
          </div>
        ),
        id: 'F004',
        title: `${t('sds_detail:section')} 5`,
      },
      {
        content: (
          <div style={ACTION_STYLE}>
            <img
              alt="e-spill-kit"
              style={ACTION_IMG_STYLE}
              src={'/images/e-spill-kit.png'}
              onClick={e => {
                if (!row.id) return;
                e.preventDefault();
                e.stopPropagation();
                setSdsDetailPopup(
                  <SDSDetailPopup
                    sdsId={row.id as number}
                    literal={literal}
                    onClose={() => setSdsDetailPopup(null)}
                    toSection={6}
                  />,
                );
              }}
            />
          </div>
        ),
        id: 'e-spill-kit',
        title: `${t('sds_detail:section')} 6`,
      },
    ];

    return (
      <div className={classes.rowAction}>
        {ACTION_LIST.map((item, index) => (
          <div
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <IconStyled
              id={item.id}
              key={index}
              customHoverStyles={{
                backgroundColor: 'rgb(98 109 249 / 20%)!important',
                '& *': {
                  backgroundColor: 'transparent!important',
                },
              }}
            >
              {item.title ? (
                <CustomTooltip title={item.title} placement="bottom-end">
                  {item.content}
                </CustomTooltip>
              ) : (
                item.content
              )}
            </IconStyled>
          </div>
        ))}
      </div>
    );
  };

  const renderValue = (
    item: GetAllPdfsResponseDto,
    id: string,
  ): ReactElement | string | number | null => {
    if (!item) return null;
    if (id === 'actions') return renderRowActions(item);
    return item[id as keyof GetAllPdfsResponseDto] as string | number | null;
  };

  const renderRegionSelect = (): ReactElement => {
    return (
      <RegionSelect
        id="global-search-sds"
        value={regions}
        disabled={false}
        onChange={(regions: Array<FilterOption<string>>) => {
          regions.some(region => region.value.toLowerCase() === 'any')
            ? setRegions(
                regions.filter(region => region.value.toLowerCase() === 'any'),
              )
            : setRegions(regions);
        }}
        optionDisabled={(option: FilterOption<string>) => {
          if (regions.some(region => region.value.toLowerCase() === 'any')) {
            if (option.value.toLowerCase() === 'any') return false;
            else return true;
          } else return false;
        }}
      />
    );
  };

  const renderResult = (): ReactElement => {
    if (errorText) {
      return <div className={classes.errorText}>{errorText}</div>;
    }

    if (isMobile()) {
      return (
        <CardTableMobile
          column={TABLE_COLUMNS.map((col: TableColumn) => ({
            ...col,
            title: t(col.title),
          }))}
          data={data.results}
          renderValue={(item: GetAllPdfsResponseDto, id: string) =>
            renderValue(item, id)
          }
          count={data.count}
          page={page + 1}
          handlePageChange={(e: any, value: number) => setPage(value - 1)}
        />
      );
    }

    return (
      <EnhancedTable
        columns={TABLE_COLUMNS}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        count={data.count}
        dataRows={data.results}
        renderRow={(item: GetAllPdfsResponseDto, id: string) =>
          renderValue(item, id)
        }
        renderInLocationMessage={(item: GetAllPdfsResponseDto) => {
          if (
            item.used_in_locations_data &&
            item.used_in_locations_data.length > 0
          )
            return (
              <Typography className={classes.inUseRowText}>
                {t('global_search_sds:already_in_your_library')}
              </Typography>
            );
          else return null;
        }}
        needTranslated={true}
        classNameRow={(row: GetAllPdfsResponseDto) => {
          if (
            row.used_in_locations_data &&
            row.used_in_locations_data.length > 0
          )
            return classes.inUseRowColor;
          return '';
        }}
        onRowClick={(row: GetAllPdfsResponseDto) =>
          setSdsDetailPopup(
            <SDSDetailPopup
              sdsId={row.id as number}
              literal={literal}
              onClose={() => setSdsDetailPopup(null)}
            />,
          )
        }
      />
    );
  };

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }
    onGlobalSearchSDS();
  }, [language, minYear, regions, searchInUse, page, rowsPerPage]);

  useEffect(() => {
    if (defaultSearch) {
      const value = { ...searchValues, keyword: defaultSearch };
      setSearchValues(value);
      onGlobalSearchSDS(value);
    }
  }, [defaultSearch]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.headerActionGroup}>
            <ArrowBackIcon style={{ fontSize: '16px' }} />
            <CustomTooltip title={t('common:all_my_sds')} placement="top">
              <HomeIcon
                onClick={() => onSwitchPage(APP_PAGE.MY_SDS)}
                className={classes.actionIcon}
              />
            </CustomTooltip>
          </div>
          <Typography variant="h5"> {t('global_search_sds:title')} </Typography>
        </div>

        <div className={classes.content}>
          <div className={classes.searchContainer}>
            <div className={classes.searchBarContainer}>
              <div className={`${classes.searchBarContent} globalSearchInput`}>
                {!advancedSearch ? (
                  <SearchBar
                    id="global-search-sds-common-search-bar"
                    placeHolder={t('global_search_sds:search_placeholder')}
                    onSearch={(_: string) => onGlobalSearchSDS()}
                    onChange={(text: string) =>
                      setSearchValues({ ...searchValues, keyword: text })
                    }
                    defaultValue={searchValues.keyword}
                    animationCount={animationCounter}
                    wrapperStyle={{ width: '100%' }}
                  />
                ) : (
                  <div className={classes.advancedSearchBarContent}>
                    <AdvancedSearchBar
                      id="global-search-sds-advanced-search-product-name"
                      placeHolder={t('common:product_name')}
                      onSearch={(_: string) => onGlobalSearchSDS()}
                      onChange={(text: string) =>
                        setSearchValues({ ...searchValues, productName: text })
                      }
                      defaultValue={searchValues.productName}
                      wrapperStyle={
                        isMobile() ? { width: '100%' } : { width: '25%' }
                      }
                    />
                    <AdvancedSearchBar
                      id="global-search-sds-advanced-search-supplier-name"
                      placeHolder={t('common:supplier_name')}
                      onSearch={(_: string) => onGlobalSearchSDS()}
                      onChange={(text: string) =>
                        setSearchValues({ ...searchValues, supplierName: text })
                      }
                      defaultValue={searchValues.supplierName}
                      wrapperStyle={
                        isMobile() ? { width: '100%' } : { width: '25%' }
                      }
                    />
                    <AdvancedSearchBar
                      id="global-search-sds-advanced-search-product-code"
                      placeHolder={t('common:product_code')}
                      onSearch={(_: string) => onGlobalSearchSDS()}
                      onChange={(text: string) =>
                        setSearchValues({ ...searchValues, productCode: text })
                      }
                      defaultValue={searchValues.productCode}
                      wrapperStyle={
                        isMobile() ? { width: '100%' } : { width: '25%' }
                      }
                    />
                    <AdvancedSearchBar
                      id="global-search-sds-advanced-search-cas-no"
                      placeHolder="EC/CAS, UFI#"
                      onSearch={(_: string) => onGlobalSearchSDS()}
                      onChange={(text: string) =>
                        setSearchValues({ ...searchValues, casNo: text })
                      }
                      defaultValue={searchValues.casNo}
                      wrapperStyle={
                        isMobile() ? { width: '100%' } : { width: '25%' }
                      }
                    />
                    <IconButton
                      className={classes.searchIcon}
                      title={t('global_search_sds:search_sds')}
                      onClick={() => onGlobalSearchSDS()}
                    >
                      <SearchIcon
                        height={18}
                        width={18}
                        style={{
                          cursor: 'pointer',
                          fontSize: 24,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: 8,
                          boxSizing: 'unset',
                        }}
                      />
                    </IconButton>
                  </div>
                )}
              </div>

              <div className={classes.advancedSearchBtn}>
                <AdvancedSearchButton
                  countFilter={filterCount}
                  onClick={() => {
                    setAdvancedSearch(!advancedSearch);
                    setAnimationCounter(animationCounter + 1);
                  }}
                  sx={{
                    fontWeight: 400,
                    fontSize: 12,
                    padding: '6px 15px',
                    border: '1px solid rgb(128 128 128 / 20%)',
                  }}
                  style={
                    advancedSearch
                      ? {
                          backgroundColor: 'rgb(128 128 128 / 50%)',
                          color: '#FFF',
                        }
                      : {
                          backgroundColor: '#fff',
                          color: 'gray',
                        }
                  }
                >
                  <Typography
                    style={{
                      color: advancedSearch ? '#FFF' : '#000',
                      marginLeft: 5,
                    }}
                  >
                    {t('global_search_sds:advanced_search')}
                  </Typography>
                </AdvancedSearchButton>
              </div>
            </div>

            <Collapse in={advancedSearch}>
              <div className={classes.advancedSearchFilter}>
                <div className={classes.advancedSearchSelectWrapper}>
                  <div className={classes.advancedSearchSelectGroup}>
                    <LanguageSelect
                      id="global-search-sds"
                      value={language}
                      onChange={(lang: string) => setLanguage(lang)}
                    />
                    <RevisionYearSelect
                      id="global-search-sds"
                      value={minYear}
                      onChange={(year: string) => setMinYear(year)}
                      defaultValue={minYear}
                    />
                    {!isMobile() && renderRegionSelect()}
                  </div>
                  {isMobile() && (
                    <div style={{ paddingTop: '15px' }}>
                      {renderRegionSelect()}
                    </div>
                  )}
                </div>
                <div className={classes.inUseChecbox}>
                  <Checkbox
                    style={{
                      color: '#626DF9',
                      marginRight: '10px',
                    }}
                    onClick={() => setSearchInUse(!searchInUse)}
                    checked={searchInUse}
                  />
                  <Typography>
                    {t('global_search_sds:search_only_among_in_use')}
                  </Typography>
                </div>
              </div>
            </Collapse>
          </div>

          <div className={classes.resultContainer}>
            {startSearch && (
              <div className={classes.resultContent}>
                {!loading && !errorText && (
                  <Typography fontWeight={600}>
                    {' '}
                    {t('global_search_sds:exact_match_results')}:{' '}
                  </Typography>
                )}

                {!loading ? (
                  renderResult()
                ) : (
                  <div className={classes.loadingContent}>
                    <CustomLoader />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {sdsDetailPopup}
    </>
  );
};

export default GlobalSearchSDS;
