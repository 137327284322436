import useStyles from './styles';
import { isMobile } from 'utils';
import { SDSIcon, SDSInterface } from 'interfaces';
import { ColumnHeaderInterface } from 'interfaces/card-table';
/* Components */
import { ClassNameMap } from '@mui/material';
import RiskValue from 'components/risk-value';
import CustomTooltip from 'components/custom-tooltip';

interface SubstanceColValueProps {
  column: ColumnHeaderInterface;
  row: SDSInterface;
}

const SubstanceColValue = ({ column, row }: SubstanceColValueProps) => {
  const classes: ClassNameMap = useStyles();

  if (column.id === 'product_name' && !isMobile()) {
    return (
      <>
        <div>{row.product_name}</div>
        <div className={classes.chemicalSynonyms}>
          {row.sds_info?.chemical_name_synonyms}
        </div>
      </>
    );
  }

  if (column.id === 'substance_hazard') {
    if (row.sds_info) {
      const sdsInfo = row.sds_info;
      return (
        <div className={classes.risksValuesWrapper}>
          <RiskValue
            value={sdsInfo?.health_risk && Number(sdsInfo?.health_risk)}
            prefix={'H'}
            variant={'outlined'}
          />
          <RiskValue
            value={sdsInfo?.safety_risk && Number(sdsInfo?.safety_risk)}
            prefix={'S'}
            variant={'outlined'}
          />
          <RiskValue
            value={
              sdsInfo?.environment_risk && Number(sdsInfo?.environment_risk)
            }
            prefix={'E'}
            variant={'outlined'}
          />
        </div>
      );
    }
    return null;
  }

  if (column.id === 'pictograms_controls') {
    if (!row?.icons?.length) return null;
    return (
      <div className={classes.tableMobilePictogram}>
        {row.icons.map((item: SDSIcon, index: number) => {
          return (
            <CustomTooltip
              key={`substance-${row.id}-icon-${index}`}
              title={item.description}
              mobileEnabled={true}
            >
              <img
                style={
                  isMobile()
                    ? { marginRight: 5, width: 28, height: 28 }
                    : { marginRight: 5, width: 32, height: 32 }
                }
                src={item.url}
                alt=""
              />
            </CustomTooltip>
          );
        })}
      </div>
    );
  }

  if (column.id === 'amount') {
    return (
      <>
        <div className={classes.amountValue}>
          {row.substance_amount && (
            <>
              {row.substance_amount.amount} ({row.substance_amount.measure})
            </>
          )}
        </div>
      </>
    );
  }

  if (column.id === 'signal_word') {
    return (
      <> {row.sds_info && row.sds_info[column.id as keyof SDSInterface]} </>
    );
  }

  return <> {row[column.id as keyof SDSInterface]} </>;
};

export default SubstanceColValue;
