import useStyles from './styles';
import { isMobile } from 'utils';
import {
  Button,
  ClassNameMap,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconStyled from 'components/icons-styled';

interface InstallationPopupProps {
  onInstall: () => void;
  onClose: () => void;
}

const InstallationPopup = ({ onInstall, onClose }: InstallationPopupProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();

  return (
    <Dialog
      open={true}
      disableEnforceFocus
      className={classes.dialogContainer}
      fullScreen={isMobile()}
    >
      <DialogContent sx={{ paddingBottom: '16px', paddingTop: '16px' }}>
        <div className={classes.content}>
          <div className={classes.titleContent}>
            <IconStyled
              customHoverStyles={{
                backgroundColor: 'rgb(98 109 249 / 20%)!important',
                '& *': {
                  backgroundColor: 'transparent!important',
                },
              }}
            >
              <img
                alt="logo"
                src={'/images/app-icon.svg'}
                className={classes.appIcon}
              />
            </IconStyled>
            <Typography variant="h5">
              {t('common:install_sds_manager_app')}
            </Typography>
          </div>
          <Typography variant="body1">
            {t('common:app_provides_offline_access')}
          </Typography>
          <Typography variant="body1">
            {t('common:it_will_automatically_check_for_updates')}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionContainer}>
          <Button
            className={classes.actionBtn}
            onClick={onInstall}
            variant="contained"
          >
            {t('common:install')}
          </Button>
          <Button
            className={classes.actionBtn}
            onClick={onClose}
            variant="outlined"
          >
            {t('common:remind_me_later')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default InstallationPopup;
