import React from 'react';
import { Icon, IconProps } from '@mui/material';
import Search from './search.svg';

interface SearchIconProps extends IconProps {
  width: number;
  height: number;
}

const SearchIcon: React.FC<SearchIconProps> = ({ width, height, ...props }) => (
  <Icon style={{ ...props.style }}>
    <img alt="Icon" src={Search} height={height} width={width} />
  </Icon>
);

export default SearchIcon;
