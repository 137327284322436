import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  advancedSearchWrapper: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    minWidth: '130px',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #CCCCCC',
    borderRadius: 50,
    alignItems: 'center',
    '@media(max-width: 1456px)': {
      flexBasis: 'calc(50% - 7px)',
    },
    '@media(max-width: 820px)': {
      flexBasis: '100%',
    },
  },
  searchInput: {
    fontSize: '14px',
    border: 'none',
    width: '100%',
    padding: '0px 0px 0px 10px',
    fontFamily: 'Montserrat, sans-serif',
    backgroundColor: '#fff!important',
    borderRadius: 50,
    '&:focus': {
      outline: 'none',
    },
    '&:before, &:after': {
      border: '0 !important',
    },
  },
}));

export default useStyles;
