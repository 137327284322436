import { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import useStyles from './styles';
import { CSSProperties } from '@mui/styles';
import { IconButton, Input, ClassNameMap } from '@mui/material';
import { Clear } from '@mui/icons-material';
import SearchIcon from 'assets/search-icon';

interface SearchBarProps {
  id: string;
  placeHolder?: string;
  defaultValue?: string;
  onChange?: (text: string) => void;
  onSearch: (text: string) => void;
  onClear?: () => void;
  animationCount?: number;
  wrapperStyle?: CSSProperties;
}

const SearchBar = ({
  id,
  placeHolder,
  defaultValue,
  onChange,
  onSearch,
  onClear,
  animationCount = 0,
  wrapperStyle = {},
}: SearchBarProps) => {
  const classes: ClassNameMap = useStyles();
  const [value, setValue] = useState<string>('');

  const onTypeSearch = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearch(value);
    }
  };

  useEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  return (
    <div
      className={
        animationCount < 1
          ? classes.searchWrapperAnimation
          : classes.searchWrapper
      }
      style={wrapperStyle}
    >
      <Input
        className={classes.searchInput}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value);
          if (onChange) onChange(e.target.value);
        }}
        id={id}
        onKeyDown={onTypeSearch}
        placeholder={placeHolder}
        inputProps={{ maxLength: 255 }}
        endAdornment={
          <IconButton
            onClick={() => onSearch(value)}
            className={
              animationCount < 1
                ? classes.iconButtonAnimation
                : classes.iconButton
            }
          >
            <SearchIcon
              height={18}
              width={18}
              style={{
                cursor: 'pointer',
                fontSize: 24,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 8,
                boxSizing: 'unset',
              }}
            />
          </IconButton>
        }
      />

      {value && onClear ? (
        <Clear
          className={classes.clearTextButton}
          onClick={() => {
            if (!value) return;
            onClear();
            setValue('');
          }}
        />
      ) : null}
    </div>
  );
};

export default SearchBar;
