import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tableHeading: {
    '&.MuiTableCell-head': {
      color: '#00324E',
      fontWeight: 'bold',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      paddingLeft: '15px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  codeHeader: {
    fontSize: '0.98rem',
    paddingLeft: '15px',
    paddingRight: 10,
    width: '24%',
    [theme.breakpoints.down('sm')]: {
      width: '30%',
    },
  },
  statementValue: {
    position: 'relative',
    color: '#00324E',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: 'none!important',
    padding: '0!important',
    minHeight: '30px',
  },
}));

export default useStyles;
