import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  inputField: {
    minWidth: 200,
    fontSize: 14, 
    maxWidth: 200,
    "& button[title='Clear']": {
      visibility: "visible"
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  valueField: {
    maxWidth: 110,
    marginRight: "7px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%'
    }
  }
}));

export default useStyles;
