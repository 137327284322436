import availableLanguages from './languages';
import Regions from './regions';

const LANGUAGES = availableLanguages;
LANGUAGES.sort((x, y) => {
  const xValue = x.name.toUpperCase();
  const yValue = y.name.toUpperCase();
  return xValue < yValue ? -1 : xValue > yValue ? 1 : 0;
});

const REGIONS = Regions;
REGIONS.sort((x, y) => {
  const xValue = x.name.toUpperCase();
  const yValue = y.name.toUpperCase();
  return xValue < yValue ? -1 : xValue > yValue ? 1 : 0;
});

export { LANGUAGES, REGIONS };
