import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  tableRow: {
    cursor: 'pointer',
  },
  inLocationMessageCell: {
    width: 0,
    position: 'relative',
    left: '-50%',
    verticalAlign: 'bottom',
    margin: 0,
    padding: 0,
    border: 'none',
  },
  menu: {
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      fontFamily: 'Montserrat, sans-serif',
    },
    '& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper':
      {
        boxShadow:
          'rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 0px 0px 1px, rgb(0 0 0 / 5%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px',
      },
  },
}));

export default useStyles;
