import useStyles from './styles';
import { ClassNameMap, Skeleton } from '@mui/material';

interface CardTableLoadingMobileProps {
  count?: number;
}

const CardTableLoadingMobile = ({ count = 1 }: CardTableLoadingMobileProps) => {
  const classes: ClassNameMap = useStyles();

  return (
    <div className={classes.container}>
      {count > 0 &&
        [...Array(count)].map((_, index: number) => (
          <div key={index} className={classes.cardContainer}>
            <Skeleton
              key={index}
              sx={{
                width: '100%',
                height: '273px',
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default CardTableLoadingMobile;
