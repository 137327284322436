import CardTableSubstance from './card-table-substance';
import { getTableColumns } from '../helper';
import { ColumnHeaderInterface } from 'interfaces/card-table';
import { SDSInterface } from 'interfaces';

interface CardTableMobileProps {
  data: Array<SDSInterface>;
  onViewSubstanceDetail: (data: SDSInterface, toSection?: number) => void;
}

const CardTableMobile = ({
  data,
  onViewSubstanceDetail,
}: CardTableMobileProps) => {
  const columns: Array<ColumnHeaderInterface> = getTableColumns();

  return (
    <>
      {data.map((item: SDSInterface, key: number) => (
        <CardTableSubstance
          key={key}
          data={item}
          columns={columns}
          onViewSubstanceDetail={(toSection?: number) =>
            onViewSubstanceDetail(item, toSection)
          }
        />
      ))}
    </>
  );
};

export default CardTableMobile;
