import useStyles from './styles';
import { isMobile } from 'utils';
import { useViewport } from 'hooks/useViewport';
import { Dialog, DialogTitle } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface PdfViewPopupProps {
  url: string;
  onClose: () => void;
}

const PdfViewPopup = ({ url, onClose }: PdfViewPopupProps) => {
  const classes = useStyles();
  const { width } = useViewport();

  return (
    <Dialog
      maxWidth="lg"
      onClose={() => onClose()}
      open={true}
      disableEnforceFocus
      fullScreen={isMobile() || width < 1024}
      className={classes.dialogContainer}
    >
      <DialogTitle
        sx={{
          padding: 0,
          paddingBottom: '4px',
        }}
      >
        <div className={classes.dialogTitleContainer}>
          <div className={classes.closeIcon} onClick={() => onClose()}>
            <CloseIcon fontSize="small" />
          </div>
        </div>
      </DialogTitle>
      <iframe
        style={{ width: 'calc(100% - 4px)', height: 'calc(100% - 46px)' }}
        title="pdf-view"
        src={url}
      />
    </Dialog>
  );
};

export default PdfViewPopup;
