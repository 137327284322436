import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    overflow: 'auto',
  },
}));

export default useStyles;
