import useStyles from './styles';
/* Components */
import { ClassNameMap, Typography } from '@mui/material';
import PdfViewButton from 'components/pdf-view-button';

interface SDSDetailHeaderProps {
  signalWord: string;
  substanceName: string;
  substanceSupplier: string;
  pdfUrl?: string | null;
}

const SDSDetailHeader = ({
  signalWord,
  substanceName,
  substanceSupplier,
  pdfUrl,
}: SDSDetailHeaderProps) => {
  const classes: ClassNameMap = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.actionGroup}>
        {pdfUrl && (
          <div className={classes.pdfIcon}>
            <PdfViewButton url={pdfUrl} styled={false} />
          </div>
        )}
        <div className={classes.emptyBlock} />
      </div>
      <div className={classes.signalWordContent}>
        <div className={classes.signalWordValue}>
          <div>{signalWord}</div>
        </div>
      </div>
      <div className={classes.infoContent}>
        <Typography variant="h4" className={classes.heading}>
          {substanceName && substanceName}
        </Typography>
        <Typography variant="h6" className={classes.subHeading}>
          {substanceSupplier && substanceSupplier}
        </Typography>
      </div>
    </div>
  );
};

export default SDSDetailHeader;
