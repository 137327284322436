import { ReactElement } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { SDSInterface } from 'interfaces';
import { ClassNameMap } from '@mui/material';
import PdfViewButton from 'components/pdf-view-button';
import CustomTooltip from 'components/custom-tooltip';

interface ActionItemInterface {
  id: string;
  content: ReactElement;
  title?: string;
  hide?: boolean;
}

interface CardTableActionProps {
  data: SDSInterface;
  onViewSubstanceDetail: (toSection?: number) => void;
}

const CardTableAction = ({
  data,
  onViewSubstanceDetail,
}: CardTableActionProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();

  const ACTION_LIST: Array<ActionItemInterface> = [
    {
      content: <PdfViewButton url={data.public_view_url} />,
      id: 'view-pdf',
    },
    {
      content: (
        <div className={classes.actionImgContainer}>
          <img
            alt="e-medical-attention-icon"
            className={classes.actionImg}
            src={'/images/e-medical-attention.png'}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onViewSubstanceDetail(4);
            }}
          />
        </div>
      ),
      id: 'e-medical-attention',
      title: t('sds_detail:first_aid'),
      hide: !!data?.sds_other_info && data.sds_other_info['4']?.length === 0,
    },
    {
      content: (
        <div className={classes.actionImgContainer}>
          <img
            alt="F004-icon"
            className={classes.actionImg}
            src={'/images/F004.png'}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onViewSubstanceDetail(5);
            }}
          />
        </div>
      ),
      id: 'F004',
      title: t('sds_detail:firefighting'),
      hide: !!data?.sds_other_info && data.sds_other_info['5']?.length === 0,
    },
    {
      content: (
        <div className={classes.actionImgContainer}>
          <img
            alt="e-spill-kit-icon"
            className={classes.actionImg}
            src={'/images/e-spill-kit.png'}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onViewSubstanceDetail(6);
            }}
          />
        </div>
      ),
      id: 'e-spill-kit',
      title: t('sds_detail:accidental_release'),
      hide: !!data?.sds_other_info && data.sds_other_info['6']?.length === 0,
    },
  ];

  return (
    <div className={classes.container}>
      {ACTION_LIST.map((item: ActionItemInterface) => (
        <div
          key={item.id}
          className={classes.actionItem}
          style={{ display: item.hide ? 'none' : 'flex' }}
        >
          {item.title ? (
            <CustomTooltip title={item.title}>
              <span>{item.content}</span>
            </CustomTooltip>
          ) : (
            item.content
          )}
        </div>
      ))}
    </div>
  );
};

export default CardTableAction;
