import { ReactElement } from 'react';
import useStyles from './styles';
import { ColumnHeaderInterface } from 'interfaces/card-table';
import { SDSInterface } from 'interfaces';
import { getWrapperColStyle } from 'components/card-table/helper';
/* Components */
import { ClassNameMap } from '@mui/material';
import AlertInfo from 'components/alert-info';
import SubstanceColValue from 'components/card-table/substance-column-value';
import CardTableAction from 'components/card-table/card-table-action';

interface CardTableSubstanceProps {
  columns: Array<ColumnHeaderInterface>;
  data: SDSInterface;
  onViewSubstanceDetail: (toSection?: number) => void;
}

const CardTableSubstance = ({
  columns,
  data,
  onViewSubstanceDetail,
}: CardTableSubstanceProps) => {
  const classes: ClassNameMap = useStyles();

  const getFieldTemplate = (
    column: ColumnHeaderInterface,
    item: SDSInterface,
    index: number,
  ): ReactElement | null => {
    const columnStyle: { [name: string]: any } = getWrapperColStyle(
      column,
      item,
    );

    return (
      <div
        key={index}
        className={classes.cardTableField}
        style={{ ...columnStyle }}
      >
        <SubstanceColValue column={column} row={item} />
      </div>
    );
  };

  return (
    <div
      className={classes.container}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onViewSubstanceDetail();
      }}
    >
      <div className={classes.cardContent}>
        <div className={classes.cardTableFieldsList}>
          {columns.map((column: ColumnHeaderInterface, index: number) =>
            getFieldTemplate(column, data, index),
          )}
        </div>

        {!!data.info_msg && (
          <AlertInfo
            infoMsg={data.info_msg}
            textStyle={{
              fontSize: '12px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
            alertContainerStyle={{
              padding: '0px 14px',
              marginTop: '10px',
            }}
          />
        )}

        <CardTableAction
          data={data}
          onViewSubstanceDetail={onViewSubstanceDetail}
        />
      </div>
    </div>
  );
};

export default CardTableSubstance;
