import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 20px',
    background: '#F2F5F7',
    height: 'calc(100vh - 10px)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    marginBottom: '20px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
    },
  },
  subHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  scanIcon: {
    cursor: 'pointer',
    color: '#898CFA',
    fontSize: '40px',
  },
  pageTitle: {
    width: '100%',
    display: 'flex',
    gap: '6px',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  logo: {
    width: 'auto',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
    },
  },
  headerActionGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  headerActionBtnGroup: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  searchBarWrapper: {
    width: 410,
    minHeight: 'auto',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  actionIcon: {
    cursor: 'pointer',
    color: '#898CFA',
  },
  cardTableContainer: {
    width: '100%',
    flex: 1,
    overflow: 'auto',
    marginBottom: '1px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
  },
  cardTableActionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  actionGroupMenu: {
    '& span': {
      marginLeft: 15,
    },
    '& .MuiMenuItem-root': {
      borderBottom: '1px solid rgba(128,128,128,0.5)',
    },
  },
  noSearchDataAlert: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginTop: '20px',
    gap: '10px',
  },
}));

export default useStyles;
