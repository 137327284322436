import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const notifyUserAboutUpdate = (registration: ServiceWorkerRegistration) => {
  // Dispatch an event or set a state to trigger the snackbar UI
  if (window.dispatchEvent) {
    window.dispatchEvent(
      new CustomEvent('swUpdateAvailable', { detail: registration }),
    );
  }
};

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered:', registration);
        // Check if the page is already controlled
        if (!navigator.serviceWorker.controller) {
          console.log('Reloading page to enable service worker control...');
          window.location.reload(); // Reloads to allow the service worker to take control
        }

        // Check for updates
        if (registration.waiting) {
          notifyUserAboutUpdate(registration);
        }

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // New update available
                  notifyUserAboutUpdate(registration);
                }
              }
            };
          }
        };
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
