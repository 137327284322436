import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  scannerPopupTitle: {
    [theme.breakpoints.down('sm')]: {
      background: '#626DF9',
      color: '#fff',
      width: '100%',
      boxSizing: 'border-box',
      '& .MuiButtonBase-root': {
        color: '#fff',
        right: 0
      },
    }
  },
}));

export default useStyles;
