import { ReactElement } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { SDSInfoCode } from 'interfaces';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ClassNameMap,
  Typography,
} from '@mui/material';

interface StatementsTableProps {
  codes: Array<SDSInfoCode>;
  sectionKey: string;
}

const StatementsTable = ({ codes, sectionKey }: StatementsTableProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();

  const renderStatement = (row: SDSInfoCode): ReactElement => {
    return (
      <TableCell component="td" scope="row" style={{ paddingLeft: '15px' }}>
        <Typography className={classes.statementValue}>
          {row.translation ?? row.statements}
        </Typography>
      </TableCell>
    );
  };

  return (
    <Table
      classes={{ root: classes.tableContainer }}
      size="small"
      aria-label="a dense table"
    >
      <TableHead>
        <TableRow>
          <TableCell
            className={`${classes.tableHeading} ${classes.codeHeader}`}
          >
            {t('sds_detail:code')}
          </TableCell>
          <TableCell className={classes.tableHeading}>
            {t('sds_detail:statements')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {codes &&
          codes.map((row: SDSInfoCode, i: number) => (
            <TableRow
              key={`${sectionKey}_${i}`}
              style={{
                wordBreak: 'break-word',
                minHeight: '30px',
              }}
            >
              <TableCell
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  paddingLeft: '15px',
                }}
                component="td"
                scope="row"
              >
                {row.statement_code}
              </TableCell>
              {renderStatement(row)}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default StatementsTable;
