import { ReactElement } from 'react';
import useStyles from './styles';
import { makeStyles } from '@mui/styles';
import { ClassNameMap } from '@mui/material';

interface IconStyledProps {
  id?: string;
  children: ReactElement;
  wrapperStyle?: any;
  color?: string;
  customHoverStyles?: any;
}

const IconStyled = ({
  children,
  wrapperStyle,
  color = 'default',
  id = '',
  customHoverStyles = {},
}: IconStyledProps) => {
  const classes: ClassNameMap = useStyles();
  const backgroundColor =
    color === 'none'
      ? 'transparent'
      : color === 'primary'
        ? 'rgb(212 215 255)'
        : '#F2F5F7';
  const iconColor = color === 'primary' ? 'rgb(98, 109, 249)' : '#52525F';
  const hoverUseStyle = makeStyles(() => ({
    hoverStyle: {
      '&:hover': {
        ...customHoverStyles,
      },
    },
  }));

  return (
    <div
      id={id}
      style={{
        ...wrapperStyle,
        backgroundColor,
        color: iconColor,
      }}
      className={`${classes.wrapper} ${hoverUseStyle().hoverStyle}`}
    >
      {children}
    </div>
  );
};

export default IconStyled;
