import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  cardTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  cardTableSticky: {
    position: 'sticky',
    top: -1,
    zIndex: 2,
    backgroundColor: '#F2F5F7',
    width: 'fit-content',
    minWidth: 'calc(100% - 2px)',
  },
  cardTableHeader: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #cccccc8f',
    borderRadius: '4px',
    display: 'flex',
    color: '#52525F',
    minWidth: 'calc(100% - 2px)',
    width: 'fit-content',
    position: 'sticky',
    top: 0,
    marginBottom: 4,
  },
  cardTableHeaderWrapper: {
    display: 'flex',
    flex: 1,
  },
  cardTableHeaderCol: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    flexShrink: 0,
    fontSize: 14,
    fontWeight: '600',
    padding: '20px 20px 20px 0px',
    '&:first-child': {
      paddingLeft: 10,
      flex: 1,
    },
    '&:nth-child(2)': {
      flex: 1,
    },
  },
  hseWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
    width: '100%',
  },
  hseValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    '& .defaultArrowIcon': {
      display: 'none !important',
    },
  },
  cardHeadingActions: {
    justifyContent: 'left',
    width: '154px',
    fontSize: 14,
    fontWeight: '600',
    paddingTop: '20px',
    textAlign: 'left',
    paddingLeft: '10px',
  },
}));

export default useStyles;
