import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  searchWrapper: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    padding: '5px 20px',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #CCCCCC',
    borderRadius: 4,
    paddingLeft: '18px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '6px 10px',
    },
  },
  searchInput: {
    fontSize: '14px',
    border: 'none',
    marginLeft: '5px',
    width: '100%',
    fontFamily: 'Montserrat, sans-serif',
    '&:focus': {
      outline: 'none',
    },
    '&:before, &:after': {
      border: '0 !important',
    },
  },
  clearTextButton: {
    padding: 0,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '-5px',
    gap: 7,
  },
}));

export default useStyles;
