import { useTranslation } from 'react-i18next';
import {
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  ClassNameMap,
} from '@mui/material';
import useStyles from './styles';
import { getRecentYears, isNumber } from 'utils';

interface RevisionYearSelectProps {
  id: string;
  value: string;
  defaultValue?: number | string | null;
  onChange: (value: string) => void;
}

const RevisionYearSelect = ({
  id,
  value,
  defaultValue,
  onChange,
}: RevisionYearSelectProps) => {
  const { t } = useTranslation();
  const classes: ClassNameMap = useStyles();
  const RECENT_YEARS: number[] = getRecentYears(6);

  return (
    <FormControl variant="standard">
      <InputLabel>{t('common:minimum_revision_year')}</InputLabel>
      <Select
        id={`${id}-revision-year-select`}
        labelId={`${id}-revision-year-select-input-label`}
        value={value === 'all' ? ' ' : value}
        label={`${t('common:minimum_revision_year')}`}
        className={classes.selectInput}
        onChange={(e: SelectChangeEvent<string>) => onChange(e.target.value)}
        MenuProps={{ classes: { paper: classes.selectCustomMenu } }}
      >
        <MenuItem key={99999} value={'all'}>
          {t('common:all')}
        </MenuItem>
        {RECENT_YEARS.map((year: number, i: number) => (
          <MenuItem key={i} value={year}>
            {`${year}`}
          </MenuItem>
        ))}
        {defaultValue &&
          isNumber(String(defaultValue)) &&
          RECENT_YEARS.indexOf(Number(defaultValue)) === -1 && (
            <MenuItem
              value={defaultValue}
              disabled={true}
              style={{ display: 'none' }}
            >
              {`${defaultValue}`}
            </MenuItem>
          )}
      </Select>
    </FormControl>
  );
};

export default RevisionYearSelect;
