import { ReactElement } from 'react';
import useStyles from './styles';
import { ColumnHeaderInterface } from 'interfaces/card-table';
import { SDSInterface } from 'interfaces';
/* Components */
import { ClassNameMap } from '@mui/material';
import SubstanceColValue from 'components/card-table/substance-column-value';
import AlertInfo from 'components/alert-info';
import CardTableAction from 'components/card-table/card-table-action';

interface CardTableSubstanceProps {
  data: SDSInterface;
  columns: Array<ColumnHeaderInterface>;
  onViewSubstanceDetail: (toSection?: number) => void;
}

const CardTableSubstance = ({
  data,
  columns,
  onViewSubstanceDetail,
}: CardTableSubstanceProps) => {
  const classes: ClassNameMap = useStyles();

  const renderCol = (item: SDSInterface): ReactElement | null => {
    return (
      <>
        {columns.map((col, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: col.width,
                alignItems: col.align,
              }}
              className={classes.cardTableSubstanceInfoCol}
            >
              <SubstanceColValue column={col} row={item} />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={classes.cardTableSubstance}>
      <div
        style={{
          borderBottomLeftRadius: !data.info_msg ? 4 : 0,
          borderBottomRightRadius: !data.info_msg ? 4 : 0,
          borderBottomWidth: 1,
        }}
        className={classes.cardTableSubstanceInfo}
      >
        <div
          className={classes.cardTableInfoBody}
          onClick={() => onViewSubstanceDetail()}
        >
          {renderCol(data)}
        </div>

        <div className={'cardTableCeil'}>
          <CardTableAction
            data={data}
            onViewSubstanceDetail={onViewSubstanceDetail}
          />
        </div>
      </div>

      {data.info_msg && (
        <div className={classes.cardTableSubstanceInfoInfoMsg}>
          <AlertInfo
            infoMsg={{
              content: data.info_msg.content,
              type: data.info_msg.type,
            }}
            textStyle={{
              fontSize: '12px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: '800px',
            }}
            alertContainerStyle={{
              padding: '0px 14px',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CardTableSubstance;
