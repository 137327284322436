import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  risksValuesWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: 10,
    '@media(max-width: 767px)': {
      justifyContent: 'flex-end',
      gap: 10,
    },
  },
  tableMobilePictogram: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 7,
  },
  chemicalSynonyms: {
    fontSize: 10,
    marginTop: 20,
    color: '#52525F',
    fontWeight: 600,
  },
  amountValue: {
    display: 'flex',
  },
}));

export default useStyles;
