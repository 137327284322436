import { SDSInterface } from 'interfaces';
import { ColumnHeaderInterface } from 'interfaces/card-table';
import { isMobile } from 'utils';

export const COLUMN_GAP = 20;
export const ACTION_WIDTH = 214;
export const TABLE_LEFT_PADDING = 10;
export const ADDITIONAL_COLUMN_WIDTH = 260;

export const getTableColumns = (): Array<ColumnHeaderInterface> => {
  return [
    {
      id: 'product_name',
      width: 200,
      align: 'left',
    },
    {
      id: 'supplier_name',
      width: 200,
      align: 'left',
    },
    {
      id: 'location',
      width: 200,
      align: 'left',
    },
    {
      id: 'signal_word',
      width: 130,
      align: 'left',
    },
    {
      id: 'amount',
      width: 130,
      align: 'left',
    },
    {
      id: 'pictograms_controls',
      width: 180,
      align: 'left',
    },
    {
      id: 'substance_hazard',
      width: 130,
      align: 'center',
    },
  ];
};

export const getWrapperColStyle = (
  column: ColumnHeaderInterface,
  item?: SDSInterface,
): { [name: string]: string } => {
  if (isMobile()) {
    if (column.id === 'substance_hazard')
      return { justifyContent: 'space-between' };
    if (column.id === 'location') return { textAlign: 'end' };
    if (column.id === 'amount' && !item?.substance_amount)
      return { display: 'none' };

    if (
      column.id === 'product_synomyms' &&
      item?.sds_info?.chemical_name_synonyms === ''
    )
      return { display: 'none' };
  }
  return {};
};
