import './App.css';
import { ReactElement, useEffect, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { exportLiterals, exportUserSds, getPdfFileByUrl } from 'api/substances';
import { CACHE_NAME } from 'api';
import {
  validateToken,
  cacheFile,
  putCache,
  formatISODate,
  renderSnackbar,
  removeCache,
} from 'utils';
import AppThemeProvider from 'utils/AppThemeProvider';
import { defaultLanguage, getI18n } from 'utils/i18n';
import {
  ExportUserSDSDto,
  ExportUserSDSUpdatedDto,
  LiteralInterface,
} from 'interfaces';
import { ApiResponse, ErrorApiPayload } from 'interfaces/api';
import APP_PAGE from 'enums/page.enums';
import ERROR_API_CODE from 'enums/api-error-code';
/* Components */
import MySDS from 'pages/my-sds';
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import GlobalSearchSDS from 'pages/global-search-sds';
import InstallationPopup from 'components/popup/installation-popup';
import NoAccess from 'pages/no-access';

let initalFetchChangeData = true;

const App = () => {
  const i18n = getI18n({ lng: defaultLanguage });
  const { t } = useTranslation();
  const [access, setAccess] = useState<boolean>(true);
  const [data, setData] = useState<ExportUserSDSUpdatedDto | null>(null);
  const [literal, setLiteral] = useState<LiteralInterface | null>(null);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [installation, setInstallation] = useState<any>(null);
  const [page, setPage] = useState<APP_PAGE>(APP_PAGE.MY_SDS);
  const [newServiceWorker, setNewServiceWorker] =
    useState<ServiceWorkerRegistration | null>(null);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [installationPopup, setInstallationPopup] =
    useState<ReactElement | null>(null);
  const [defaultGlobalSearch, setDefaultGlobalSearch] = useState<string>('');

  const fetchAndDownloadPdfs = async (
    data: ExportUserSDSUpdatedDto,
  ): Promise<void> => {
    const cache = await caches.open(CACHE_NAME);
    for (const sdsId in data) {
      const pdfUrl = data[sdsId].public_view_url;
      if (pdfUrl) {
        const cachedResponse = await cache.match(pdfUrl);
        if (cachedResponse) continue;
        const downloadResponse = await getPdfFileByUrl(pdfUrl);
        if (downloadResponse.status === 200)
          cacheFile(pdfUrl, downloadResponse.data, 'application/pdf');
      }
    }
  };

  const fetchAndDownloadIcon = async (
    data: ExportUserSDSUpdatedDto,
  ): Promise<void> => {
    const cache = await caches.open(CACHE_NAME);
    for (const sdsId in data) {
      const iconList = data[sdsId].icons;
      if (iconList) {
        for (let iconItem of iconList) {
          const cachedResponse = await cache.match(iconItem.url);
          if (cachedResponse) continue;
          await fetch(iconItem.url, {
            method: 'GET',
            mode: 'no-cors',
          });
        }
      }
    }
  };

  const fetchUserSds = (
    updateLatest?: boolean,
    existedCache?: ExportUserSDSUpdatedDto | null,
    callback?: (changed: boolean) => void,
  ): void => {
    const fromDate = updateLatest
      ? localStorage.getItem('last_updated')
      : undefined;
    exportUserSds(fromDate).then(
      async (response: ApiResponse<ExportUserSDSDto | ErrorApiPayload>) => {
        switch (response.status) {
          case 200:
            const data = response.data as ExportUserSDSDto;
            const updatedData = data.updated;
            const removedData = data.removed;

            if (!updateLatest || !fromDate) {
              setData(updatedData);
              putCache('my-sds', updatedData);
              renderSnackbar(t('common:offline_data_updated'));
              if (callback !== undefined) callback(true);
              const currentTime = new Date().toISOString();
              localStorage.setItem('last_updated', currentTime);
              setLastUpdated(formatISODate(currentTime));
              return;
            }

            let dataChanged = false;
            const cacheData = existedCache ? { ...existedCache } : {};
            for (const sdsId in updatedData) {
              dataChanged = true;
              cacheData[sdsId] = updatedData[sdsId];
            }

            for (const sdsId of removedData) {
              if (sdsId in cacheData) {
                dataChanged = true;
                delete cacheData[sdsId];
              }
            }
            setData(cacheData);
            putCache('my-sds', cacheData);
            if (callback !== undefined) callback(dataChanged);
            if (dataChanged) renderSnackbar(t('common:offline_data_updated'));

            const currentTime = new Date().toISOString();
            localStorage.setItem('last_updated', currentTime);
            setLastUpdated(formatISODate(currentTime));
            return;
          case 408:
            renderSnackbar(t('common:no_internet_connection'));
            return;
          case 403:
            if (
              (response.data as ErrorApiPayload).error_code ===
              ERROR_API_CODE.OFFLINE_APP_ACCESS_DISABLED
            ) {
              setAccess(false);
              setData(null);
              setLiteral(null);
              localStorage.removeItem('last_updated');
              const removeResult = await removeCache();
              if (removeResult)
                renderSnackbar(
                  t('common:offline_app_access_disabled_by_admin'),
                );
              else renderSnackbar(t('common:something_wrong'));
            } else renderSnackbar(t('common:failed_to_fetch_data'));
            return;
          default:
            renderSnackbar(t('common:failed_to_fetch_data'));
            return;
        }
      },
    );
  };

  const fetchLiterals = (
    updateCallback?: (data: LiteralInterface) => void,
  ): void => {
    exportLiterals().then(
      (response: ApiResponse<LiteralInterface | ErrorApiPayload>) => {
        switch (response.status) {
          case 200:
            const data = response.data as LiteralInterface;
            if (!updateCallback) {
              i18n.changeLanguage(data.language ?? 'en');
              setLiteral(data);
              putCache('literals', data);
            } else updateCallback(data);
            return;
          case 403:
            if (
              (response.data as ErrorApiPayload).error_code ===
              ERROR_API_CODE.OFFLINE_APP_ACCESS_DISABLED
            )
              setLiteral(null);
            return;
          default:
            return;
        }
      },
    );
  };

  const fetchChangeData = (): void => {
    if (navigator.serviceWorker.controller) {
      fetchLiterals();
      navigator.serviceWorker.controller.postMessage({
        type: 'RELOAD_CACHE',
        url: 'my-sds',
      });
    } else console.log('Service worker controller not exist');
  };

  const renewData = (): void => {
    fetchLiterals();
    fetchUserSds();
  };

  /* Init service worker listener */
  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', event => {
      if (event.data.type === 'INIT_CACHE_RESPONSE') {
        const data = event.data.data;
        if (event.data.url === 'my-sds') {
          if (data) {
            setData(data);
            fetchChangeData();
          } else fetchUserSds();
        } else if (event.data.url === 'literals') {
          if (data) {
            i18n.changeLanguage(data.language);
            setLiteral(data);
          } else fetchLiterals();
        }
      }

      if (
        event.data.type === 'RELOAD_CACHE_RESPONSE' &&
        event.data.url === 'my-sds'
      ) {
        const cacheData = event.data.data;
        if (cacheData)
          fetchUserSds(true, cacheData, (changed: boolean) => {
            if (!changed && !initalFetchChangeData)
              renderSnackbar(t('common:no_changed_data'));
            initalFetchChangeData = false;
          });
        else fetchUserSds();
      }
    });

    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault();
      setInstallation(event);
    };

    const onServiceWorkerUpdate = (event: {
      detail: ServiceWorkerRegistration;
    }) => setNewServiceWorker(event.detail);

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('swUpdateAvailable', onServiceWorkerUpdate);
    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt,
      );
      window.removeEventListener('swUpdateAvailable', onServiceWorkerUpdate);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location?.search);
    const token = urlParams.get('token');
    if (token && validateToken(token)) {
      localStorage.setItem('access_token', token);
      window.history.pushState({}, '', window.location.pathname);
    }

    const lastUpdate = localStorage.getItem('last_updated');
    if (lastUpdate) setLastUpdated(formatISODate(lastUpdate));

    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'INIT_CACHE',
        url: 'my-sds',
      });
      navigator.serviceWorker.controller.postMessage({
        type: 'INIT_CACHE',
        url: 'literals',
      });
    }
  }, []);

  useEffect(() => {
    if (data) {
      fetchAndDownloadPdfs(data);
      fetchAndDownloadIcon(data);
    }
  }, [data]);

  useEffect(() => {
    if (installation && initialLoad) {
      setInitialLoad(false);
      setInstallationPopup(
        <InstallationPopup
          onClose={() => setInstallationPopup(null)}
          onInstall={() => {
            installation.prompt();
            installation.userChoice.then((choiceResult: any) =>
              setInstallationPopup(null),
            );
          }}
        />,
      );
    }
  }, [installation]);

  useEffect(() => {
    if (newServiceWorker && newServiceWorker.waiting) {
      enqueueSnackbar(t('common:new_version_available'), {
        persist: true,
        action: key => (
          <div style={{ display: 'flex', gap: '10px' }}>
            <p
              className="snackbar-action-btn"
              onClick={() => closeSnackbar(key)}
            >
              {t('common:remind_me_later')}
            </p>
            <p
              className="snackbar-action-btn"
              onClick={() => {
                if (newServiceWorker && newServiceWorker.waiting) {
                  newServiceWorker.waiting.postMessage({
                    type: 'SKIP_WAITING',
                  });
                  closeSnackbar(key);
                }
              }}
            >
              {t('common:upgrade')}
            </p>
          </div>
        ),
      });
    }
  }, [newServiceWorker]);

  return (
    <AppThemeProvider>
      <I18nextProvider i18n={i18n}>
        <SnackbarProvider maxSnack={3}>
          {access ? (
            <>
              <div
                style={{ display: page === APP_PAGE.MY_SDS ? 'block' : 'none' }}
              >
                <MySDS
                  data={data}
                  lastUpdated={lastUpdated}
                  literal={literal}
                  onRefresh={() => fetchChangeData()}
                  onRenew={() => renewData()}
                  onSwitchPage={(name: APP_PAGE, defaultSearch?: string) => {
                    if (defaultSearch) setDefaultGlobalSearch(defaultSearch);
                    setPage(name);
                  }}
                />
              </div>
              <div
                style={{
                  display:
                    page === APP_PAGE.GLOBAL_SEARCH_SDS ? 'block' : 'none',
                }}
              >
                <GlobalSearchSDS
                  literal={literal}
                  defaultSearch={defaultGlobalSearch}
                  onSwitchPage={(name: APP_PAGE) => setPage(name)}
                />
              </div>
            </>
          ) : (
            <NoAccess />
          )}

          {installationPopup}
        </SnackbarProvider>
      </I18nextProvider>
    </AppThemeProvider>
  );
};

export default App;
