import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.2)',
    color: 'rgb(82, 82, 95)',
    fontSize: '14px',
    border: '1px solid #E0E0E0',
    backgroundColor: '#ffffff',
    width: '100%',
    marginBottom: 12,
    padding: 12,
    boxSizing: 'border-box',
  },
  infoBlock: {
    margin: '10px 0 10px 0',
    '&:first-child': {
      marginTop: 0,
    },
  },
  valuesColor: {
    color: 'rgb(98, 109, 249)',
  },
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '15px 0',
    bottom: 0,
  },
  loader: {
    marginTop: '10%',
    marginBottom: '10%',
    [theme.breakpoints.down('sm')]: {
      margin: '30% 0',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
  },
}));

export default useStyles;
