import { CSSProperties } from 'react';
import { SDSInfoMsg } from 'interfaces';
import SDS_INFO_MSG from 'enums/info-msg.enum';
/* Components */
import { Typography, Alert } from '@mui/material';

export type AlertInfoProps = {
  infoMsg?: SDSInfoMsg | null;
  canEdit?: boolean;
  alertContainerStyle?: CSSProperties;
  textStyle?: CSSProperties;
};

export default function AlertInfo({
  infoMsg,
  alertContainerStyle = {},
  textStyle = {},
}: AlertInfoProps) {
  if (!infoMsg) return null;

  return (
    <Alert
      severity={infoMsg.type === SDS_INFO_MSG.DANGER ? 'error' : infoMsg.type}
      sx={{ ...alertContainerStyle }}
    >
      <div style={{ display: 'flex', gap: '6px' }}>
        <Typography sx={{ ...textStyle }}> {infoMsg.content} </Typography>
      </div>
    </Alert>
  );
}
