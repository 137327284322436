import { CircularProgress } from '@mui/material';
import { CSSProperties } from '@mui/styles';

interface CustomLoaderProps {
  customStyle?: CSSProperties;
}

const CustomLoader = ({ customStyle = {} }: CustomLoaderProps) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <CircularProgress
        style={
          customStyle
            ? { ...customStyle }
            : { width: '60px', height: '60px', padding: 30 }
        }
      />
    </div>
  );
};

export default CustomLoader;
